import * as React from 'react';
import PropTypes from "prop-types";

const EllipsisTextField = props => {
    let {source, emptyText, maxLen, style, options} = props;
    if (!props.record || !props.record[source]) {
        return <span>{emptyText}</span>
    }
    let text = props.record[source];
    if (!options) {
        maxLen = maxLen || 9999;
        if (text.length > maxLen) {
            text = `${text.substring(0, maxLen)}...`;
        }
        return <span>{text}</span>
    }

    const {lineMaxLen, maxLine} = options;
    const regex = new RegExp(`.{1,${lineMaxLen}}`, 'g');
    const match = text.match(regex);
    let result = [];
    if (match != null) {
        result = match.splice(0, maxLine);
    }
    const suffix = result.length === maxLine ? '...' : '';
    result = `${result.join('\n')}${suffix}`;
    return <span style={style}>{result}</span>
}

EllipsisTextField.propTypes = {
    source: PropTypes.string,
    label: PropTypes.string,
    maxLen: PropTypes.number,
    options: PropTypes.object
};

export default EllipsisTextField;
