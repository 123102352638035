import * as React from 'react';
import { FilterList, FilterListItem, Filter, SearchInput, BooleanInput, SelectInput } from 'react-admin';
import { Adjust as StatusIcon } from '@material-ui/icons';

export const SearchFilter = props => (
    <Filter {...props}>
      <SearchInput source="q" alwaysOn/>
    </Filter>
);

export const StatusFilter = () => {
      return (
          <FilterList label={'Status'} icon={<StatusIcon/>}>
            <FilterListItem label={'(not set)'} value={{
              'validStartAt_gte': null,
              'validStartAt_lte': null,
              'validEndAt_gte': null,
              'validEndAt_lte': null,
            }} />
            <FilterListItem label={'Reserved'} value={{
              'validStartAt_gte': true,
              'validStartAt_lte': undefined,
              'validEndAt_gte': true,
              'validEndAt_lte': undefined,
            }} />
            <FilterListItem label={'In Progress'} value={{
              'validStartAt_gte': undefined,
              'validStartAt_lte': true,
              'validEndAt_gte': true,
              'validEndAt_lte': undefined,
            }} />
            <FilterListItem label={'Done'} value={{
              'validStartAt_gte': undefined,
              'validStartAt_lte': true,
              'validEndAt_gte': undefined,
              'validEndAt_lte': true,
            }} />
          </FilterList>
      )
}

export const SelectFilter = (props) => {
  return (
      <FilterList {...props}>
        <Filter>
          <SelectInput {...props} optionValue={'value'} optionText={'text'} emptyText={'(not set)'} emptyValue={null} label={''} alwaysOn/>
        </Filter>
      </FilterList>
  )
};

export const BooleanFilter = (props) => {
  return (
      <Filter {...props}>
        <BooleanInput alwaysOn {...props} />
      </Filter>
  )
};

