import * as React from 'react';
import {cloneElement} from 'react';
import {
  Datagrid,
  DateField,
  downloadCSV,
  EmailField,
  List,
  sanitizeListRestProps,
  TextField,
  TopToolbar,
  useListContext,
} from 'react-admin';
import {Card as MuiCard, CardContent, withStyles} from '@material-ui/core';

import {
  CreateAtFilter,
  IsAdultFilter,
  PushAllowedFilter,
  SearchFilter,
} from './filters';

import CommonPagination from '../Components/paginations';
import {unparse as convertToCSV} from 'papaparse';
import CustomExportButton from '../custom/CustomExportButton';

const Card = withStyles(theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      order: -1,
      width: '15em',
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))(MuiCard);

const FilterSidebar = () => (
    <Card>
      <CardContent>
        <SearchFilter/>
        <CreateAtFilter/>
        <PushAllowedFilter/>
        <IsAdultFilter/>
      </CardContent>
    </Card>
);

const myExporter = data => {
  const csv = convertToCSV({
    data,
    fields: [
      'displayName',
      'createAt',
      'lastSignedAt',
      'email',
      'birthDate',
      'roleCode'],
  });
  downloadCSV(csv, 'users');
};

const ListActions = (props) => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    ...rest
  } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    showFilter,
    total,
  } = useListContext();
  return (
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {filters && cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
        <CustomExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filterValues={filterValues}
            maxResults={10}
            exporter={myExporter}
        />
      </TopToolbar>
  );
};

export const UserList = props => (
    <List {...props} title="List of Users"
          actions={<ListActions/>}
          aside={<FilterSidebar/>}
          bulkActionButtons={false}
          pagination={<CommonPagination/>}
          sort={{field: 'createAt', order: 'DESC'}}>
      <Datagrid rowClick="edit">
        <TextField source="displayName" label={'Nickname'}/>
        <DateField source="createAt" showTime={true} label={'Create at'}/>
        <DateField source="lastSignedAt" showTime={true}
                   label={'Last signed at'} emptyText="Never logged in"/>
        <EmailField source="email"/>
        <TextField source="applicationSetting.isPushAllowed"
                   label="Notification Allowed"/>
        <TextField source="isMature" label="Adult" sortable={false}/>
        <TextField source="signupGeoCountryCode" label="Country"/>
      </Datagrid>
    </List>
);

export default UserList;
