import * as React from 'react';
import {
  Edit,
  SimpleForm,
  TextField,
  Toolbar,
  SaveButton,
  TextInput,
  ReferenceField,
  DateField,
  BooleanInput,
  useEditContext,
  required,
} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';
import {CustomPageTitle} from '../Layouts';
import QuickHelpShowButton from './QuickHelpShowButton';
import {trimmedMinLength} from "../custom/customValidate";

const useCustomToolbarStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const DoNotDeleteToolbar = props => {
  const isChanged = !props.pristine;

  return (
      <Toolbar {...props} classes={useCustomToolbarStyles()}>
        <SaveButton disabled={!isChanged}/>
      </Toolbar>
  );
};

const QuickContainer = () => {
  const {record} = useEditContext();
  return (
      <QuickHelpShowButton id={record.postPublicId}/>
  )
};

export const HelpCommentEdit = (props) => {
  return (
      <Edit {...props} mutationMode="optimistic" title={<CustomPageTitle source={'contents'} {...props}/>}>
        <SimpleForm toolbar={<DoNotDeleteToolbar/>}>
          <TextField source="id" />
          <ReferenceField label="Help Id" reference="helps" source="postPublicId" fullWidth>
            <TextField source="postPublicId"/>
          </ReferenceField>
          <QuickContainer/>
          <TextInput source="comment" fullWidth multiline validate={[required(), trimmedMinLength(1)]}/>
          <DateField source="createAt" showTime/>
          <BooleanInput label={'Is deleted'} source="isDisable" />
        </SimpleForm>
      </Edit>
  )
};

export default HelpCommentEdit;
