import * as React from 'react';
import {
  Datagrid,
  DateField,
  downloadCSV,
  List,
  sanitizeListRestProps,
  TextField,
  TopToolbar,
  useListContext,
} from 'react-admin';

import CommonPagination from '../Components/paginations';
import {unparse as convertToCSV} from 'papaparse';
import CustomExportButton from '../custom/CustomExportButton';
import EllipsisTextField from '../custom/EllipsisTextField';

const myExporter = data => {
  const csv = convertToCSV({
    data,
    fields: [
      'id',
      'createAt',
      'signupCountry',
      'deviceTypes',
      'signInProvider',
      'reason',
      'adminEmail'],
  });
  downloadCSV(csv, 'deleted accounts');
};

const ListActions = (props) => {
  const {
    className,
    exporter,
    maxResults,
    ...rest
  } = props;
  const {
    currentSort,
    resource,
    total,
  } = useListContext();
  return (
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        <CustomExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            maxResults={10}
            exporter={myExporter}
        />
      </TopToolbar>
  );
};

export const DeleteAccountList = props => {
  return (
      <List {...props} title="Deleted accounts"
            actions={<ListActions/>}
            bulkActionButtons={false}
            pagination={<CommonPagination/>}
            sort={{field: 'createAt', order: 'DESC'}}>
        <Datagrid>
          <EllipsisTextField source="id" label={'User ID'}
                             maxLen={30}/>
          <DateField source="createAt" showTime label={'Deleted at'}/>
          <TextField source="signupCountry" label={'Country'}/>
          <TextField source="deviceTypes" label={'Devices'}/>
          <TextField source="signInProvider" label={'Sign in Method'}/>
          <TextField source="reason" label={'Reason'}/>
          <TextField source="adminEmail" label={'Employee'}/>
        </Datagrid>
      </List>
  );
};

export default DeleteAccountList;
