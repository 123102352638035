import * as React from 'react';
import {
  Edit,
  SimpleForm,
  TextField,
  Toolbar,
  SaveButton,
  ReferenceManyField,
  Datagrid,
  Pagination,
  DateField,
  BooleanField,
  BooleanInput,
} from 'react-admin';
import {Button} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Comment as CommentIcon} from '@material-ui/icons';
import {CustomPageTitle} from '../Layouts';
import {Link} from 'react-router-dom';
import EllipsisTextField from "../custom/EllipsisTextField";

const useCustomToolbarStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const CustomCommentButton = ({record}) => (
    <Button color={'primary'} type={'button'} variant={'contained'} startIcon={<CommentIcon />} component={Link} to={{
      pathname: '/help-comments/create',
      search: `?helpId=${record.id}`
    }}>
      Comment
    </Button>
);

const HelpToolbar = props => {
  const isChanged = !props.pristine;

  return (
      <Toolbar {...props} classes={useCustomToolbarStyles()}>
        <SaveButton disabled={!isChanged}/>
        <CustomCommentButton />
      </Toolbar>
  );
};

export const HelpEdit = (props) => (
    <Edit {...props} mutationMode="optimistic" title={<CustomPageTitle source={'title'} {...props} />}>
      <SimpleForm undoable={false} toolbar={<HelpToolbar/>}>
          <TextField source="id"/>
          <TextField source="title" fullWidth/>
          <TextField source="content" fullWidth style={{whiteSpace: 'pre-wrap'}} />
          <DateField source="createAt" showTime/>
          <TextField source="progress" />
          <BooleanField source="isSecret" />
          <BooleanInput label={'Is deleted'} source="isDisable"/>
        <ReferenceManyField label={'Comments'} reference="help-comments"
                            target="helpId" fullWidth
                            perPage={10} pagination={<Pagination/>}
                            sort={{field: 'createAt', order: 'DESC'}}>
          <Datagrid rowClick="show" style={{width: '100%'}}>
              <EllipsisTextField source="comment" options={{
                  lineMaxLen: 50,
                  maxLine: 3
              }} style={{whiteSpace: 'pre-wrap'}} />
            <DateField source="createAt" showTime={false}/>
            <DateField source="updateAt" showTime={false}/>
            <BooleanField label={'Is deleted'} source="isDisable"/>
          </Datagrid>
        </ReferenceManyField>
      </SimpleForm>
    </Edit>
);

export default HelpEdit;
