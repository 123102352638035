import * as React from 'react';
import {AppBar} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';

import Logo from './Logo';

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
});

const CustomAppBar = (props) => {
  const classes = useStyles();
  let env = process.env.REACT_APP_ENV;
  const version = env == "localhost" || env == 'development'
      ? process.env.REACT_APP_DEV_VERSION : process.env.REACT_APP_PRD_VERSION;
  return (
      <AppBar {...props} elevation={1}>
        <Logo/>
        <Typography
            variant="h6"
            color="inherit"
            className={classes.title}
            id="react-admin-title"
        />

        <span className={classes.spacer}/>
        <span color="white"> {process.env.REACT_APP_ENV} {version} </span>
      </AppBar>
  );
};

export default CustomAppBar;
