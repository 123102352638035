import React, {useState} from 'react';
import {
  BooleanField,
  ChipField,
  Datagrid,
  DateField,
  Edit,
  EmailField,
  FormTab,
  FormWithRedirect,
  Labeled,
  Pagination,
  ReferenceManyField,
  SaveButton,
  SelectInput,
  TabbedForm,
  TextField,
  Toolbar,
  useDataProvider,
} from 'react-admin';
import {
  Button,
  Grid,
  Modal,
  TextField as MuiTextField,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {CustomPageTitle} from '../Layouts';

const DateFieldRow = props => (
    <FormWithRedirect {...props} render={formProps => (
        <Grid container spacing={4}>
          <Grid item>
            <Labeled label="Registered at">
              <DateField source="createAt" showTime
                         record={formProps.record}/>
            </Labeled>
          </Grid>
          <Grid item>
            <Labeled label="Latest signed at">
              <DateField label="lase signed at"
                         emptyText="Never logged in"
                         source="lastSignedAt" showTime
                         record={formProps.record}/>
            </Labeled>
          </Grid>
        </Grid>
    )}
    />
);

const useCustomToolbarStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const useModalStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function getModalStyle() {
  const top = 30;
  const left = 50;
  
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(${top}%, ${left}%)`,
  };
}

const DoNotDeleteToolbar = props => {
  const isChanged = !props.pristine;
  
  return (
      <Toolbar {...props} classes={useCustomToolbarStyles()}>
        <SaveButton disabled={!isChanged}/>
      </Toolbar>
  );
};

export const UserEdit = (props) => {
  
  const [open, setOpen] = useState(false);
  const [id, setId] = useState('');
  const [modalStyle] = useState(getModalStyle);
  const [reason, setReason] = useState('Write down why the user leave aJoara');
  
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleDeleteAccount = (props) => {
    let result = window.confirm(
        'Are you sure to delete this user? The action cannot be canceled.');
    if (result) {
      dataProvider.delete('users',
          {id: props.id, data: {reason: 'asdf'}});
      alert('Deleted user');
    } else {
      alert('Canceled deleting user');
    }
  };
  const classes = useModalStyles();
  
  const dataProvider = useDataProvider();
  
  const deleteAccountModal = (props) => {
    
    return (<div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">Delete Account</h2>
      <MuiTextField label="Reason" variant="filled"
                    color="primary" value={reason} fullWidth
                    onChange={e => setReason(e.target.value)}/>
      <MuiTextField label="Date" variant="filled" color="primary"
                    value={new Date().toISOString()}
                    inputProps={{readOnly: true}}
                    fullWidth/>
      <MuiTextField label="Employee" variant="filled" color="primary"
                    value={JSON.parse(localStorage.getItem('user')).email}
                    inputProps={{readOnly: true}}
                    fullWidth/>
      <Button color="error" onClick={() => handleDeleteAccount(props)}>Delete
        account</Button>
    </div>);
  };
  
  return (
      <Edit {...props} mutationMode="optimistic"
            title={<CustomPageTitle source={'displayName'} {...props}/>}>
        <TabbedForm undoable={false} toolbar={<DoNotDeleteToolbar/>}>
          <FormTab label="Info">
            <TextField source="id"/>
            <TextField source="displayName"/>
            
            <EmailField source="email"/>
            <DateField source="birthDate"/>
            
            <DateFieldRow/>
            
            <TextField label="gender" source="genderCode"/>
            <h6>Is Notification Allowed</h6>
            <BooleanField label={'is Notification Allowed'}
                          source="applicationSetting.isPushAllowed"/>
            <SelectInput label="Role" source="roleCode" choices={[
              {id: 'ADMIN', name: 'Admin'},
              {id: 'USER', name: 'User'},
            ]}/>
            <Modal open={open}
                   onClose={handleClose}>{deleteAccountModal(props)}</Modal>
            <Button onClick={handleOpen} variant="contained" color="error">Delete
              Account</Button>
          </FormTab>
          <FormTab label="Novels" path="novels">
            <ReferenceManyField label="" reference="novels"
                                target="authorUserPublicId" fullWidth
                                perPage={10}
                                pagination={<Pagination/>}
                                sort={{field: 'createAt', order: 'DESC'}}>
              <Datagrid rowClick="edit" style={{width: '100%'}}>
                <TextField source="title"/>
                <TextField source="genreName"/>
                <ChipField source="serializingStatus"/>
                <DateField source="lastChapterCreateAt" showTime={false}/>
                <DateField source="createAt" showTime={false}/>
                <BooleanField label={'is public'} source="isPublic"/>
                <BooleanField label={'Is deleted'} source="isDisable"/>
              </Datagrid>
            </ReferenceManyField>
          </FormTab>
        </TabbedForm>
      </Edit>
  );
};

export default UserEdit;
