import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ChipField,
  BooleanField,
  DateField,
  ReferenceField,
  ImageField,
  useListContext,
  downloadCSV,
  TopToolbar,
  sanitizeListRestProps,
} from 'react-admin';
import {
  Card as MuiCard,
  CardContent,
  withStyles,
} from '@material-ui/core';

import {
  CreateAtFilter,
  SearchFilter,
  SerializingStatusFilter,
  TargetFilter,
  ChapterUpdateAtFilter,
  GenreFilter,
  BooleanFilter,
} from './filters';

import CommonPagination from '../Components/paginations';
import {unparse as convertToCSV} from "papaparse";
import {cloneElement} from "react";
import CustomExportButton from "../custom/CustomExportButton";

const Card = withStyles(theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      order: -1,
      width: '15em',
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))(MuiCard);

const FilterSidebar = () => {
  return (
      <Card>
        <CardContent>
          <SearchFilter/>
          <CreateAtFilter/>
          <ChapterUpdateAtFilter/>
          <GenreFilter />
          <TargetFilter/>
          <SerializingStatusFilter/>
          <BooleanFilter source={'isPublic'} label={'Published'}/>
          <BooleanFilter source={'isDisable'} label={'Is deleted'}/>
        </CardContent>
      </Card>
  );
};

const myExporter = data => {
  const csv = convertToCSV({
    data,
    fields: ['coverImageUrl', 'title', 'genreName', 'displayName', 'createAt', 'lastChapterUpdateAt', 'targetReaderSegmentCode', 'serializingStatusCode', 'isPublic', 'isDisable'],
  });
  downloadCSV(csv, 'novels');
};

const ListActions = (props) => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    ...rest
  } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    showFilter,
    total,
  } = useListContext();
  return (
          <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
              resource,
              showFilter,
              displayedFilters,
              filterValues,
              context: 'button',
            })}
            <CustomExportButton
                    disabled={total === 0}
                    resource={resource}
                    sort={currentSort}
                    filterValues={filterValues}
                    maxResults={10}
                    exporter={myExporter}
            />
          </TopToolbar>
  );
};

export const NovelList = props => {
  return (
      <List {...props} title="List of Novels"
            aside={<FilterSidebar/>}
            actions={<ListActions />}
            bulkActionButtons={false}
            pagination={<CommonPagination/>}
            filterDefaultValues={{isPublic:true, isDisable:false, }}
            sort={{field: 'createAt', order: 'DESC'}}>
        <Datagrid rowClick="edit">
          <ImageField source="coverImageUrl" title={'title'} sortable={false}/>
          <TextField source="title" label={'Title'}/>
          <TextField source="genreName" label={'Genre'}/>
          <ReferenceField label={'Author'} reference="users" source="authorUserPublicId" sortable={false}>
            <TextField source="displayName" />
          </ReferenceField>
          <DateField source="createAt" label="Create at" showTime={true} />
          <DateField source="lastChapterUpdateAt" label="Episode update at" showTime={true} />
          <TextField source="targetReaderSegmentCode" label={'Target'}/>
          <ChipField source="serializingStatusCode" label={'Status'} />
          <BooleanField source="isPublic" label={'Published'}/>
          <BooleanField source="isDisable" label={'Is deleted'}/>
        </Datagrid>
      </List>
  );
};

export default NovelList;
