import * as React from 'react';
import {Create, required, SimpleForm, TextInput} from 'react-admin';
import {CustomPageTitle} from '../Layouts';
import RichTextInput from 'ra-input-rich-text';

export const NoticeCreate = (props) => (
    <Create {...props}
            title={<CustomPageTitle title={'New Serviece Notice'} {...props}/>}>
      <SimpleForm undoable={false}>
        <TextInput source="title" autoFocus fullWidth validate={[required()]}/>
        <RichTextInput source="content"
                       toolbar={[
                         ['bold', 'italic', 'underline'],
                         [{'align': []}],
                         [{'indent': '-1'}, {'indent': '+1'}],
                         ['blockquote', 'image']]}
                       fullWidth/>
      </SimpleForm>
    </Create>
);

export default NoticeCreate;
