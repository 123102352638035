import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  DateField,
  useListContext,
  downloadCSV,
  TopToolbar,
  sanitizeListRestProps,
  FunctionField,
} from 'react-admin';
import {
  Card as MuiCard,
  CardContent,
  withStyles,
} from '@material-ui/core';

import {
  Check as CheckIcon,
  Security as SecretIcon,
  NextWeek as ProgressIcon,
  DeviceHub as TypeIcon,
} from '@material-ui/icons';

import {
  SearchFilter,
  BooleanFilter,
  SelectFilter,
} from './filters';

import CommonPagination from '../Components/paginations';
import {unparse as convertToCSV} from "papaparse";
import {cloneElement} from "react";
import CustomExportButton from "../custom/CustomExportButton";
import EllipsisTextField from "../custom/EllipsisTextField";

const Card = withStyles(theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      order: -1,
      width: '15em',
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))(MuiCard);

const FilterSidebar = () => {
  return (
    <Card>
      <CardContent>
        <SearchFilter />
        <SelectFilter label={'Type'} icon={<TypeIcon/>} choices={[
          {value: 'SUGGESTION', text: 'Suggestion'},
          {value: 'INQUIRY', text: 'Inquiry'},
          {value: 'BUG_REPORT', text: 'Bug report'},
          {value: 'ETC', text: 'Etc'},
        ]} source={'typeCode'}/>
        <SelectFilter label={'Secret'} icon={<SecretIcon/>} choices={[
          {value: false, text: 'Public'},
          {value: true, text: 'Secret'},
        ]} source={'isSecret'}/>
        <SelectFilter label={'Progress'} icon={<ProgressIcon/>} choices={[
          {value: 'IN_PROGRESS', text: 'In progress'},
          {value: 'DONE', text: 'Done'},
        ]} source={'progress'}/>
        <BooleanFilter source={'isDisable'} label={'Is deleted'}/>
      </CardContent>
    </Card>
  );
};

const myExporter = data => {
  const csv = convertToCSV({
    data,
    fields: ['title', 'typeCode', 'createAt', 'progress', 'isSecret', 'isDisable'],
  });
  downloadCSV(csv, 'help');
};

const ListActions = (props) => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    ...rest
  } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    showFilter,
    total,
  } = useListContext();
  return (
          <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
              resource,
              showFilter,
              displayedFilters,
              filterValues,
              context: 'button',
            })}
            <CustomExportButton
                    disabled={total === 0}
                    resource={resource}
                    sort={currentSort}
                    filterValues={filterValues}
                    maxResults={10}
                    exporter={myExporter}
            />
          </TopToolbar>
  );
};

export const HelpList = props => {
  return (
    <List {...props} title="Help"
          aside={<FilterSidebar/>}
          actions={<ListActions/>}
          bulkActionButtons={false}
          pagination={<CommonPagination/>}
          filterDefaultValues={{isDisable: false}}
          sort={{field: 'createAt', order: 'DESC'}}>
      <Datagrid rowClick="edit">
        <EllipsisTextField source="title" label={'Title'} maxLen={30} />
        <TextField source="typeCode" label={'Type'}/>
        <DateField source="createAt" showTime label={'Create at'}/>
        <FunctionField source="commentCount" label="Progress" render={(record, source) => {
          return (record.commentCount > 0) ? (
            <span><CheckIcon color={'primary'}/></span>
            ) : (
            <span>in Progress...</span>
            );
        }}
        />
        <BooleanField source="isSecret" label={'Is secret'}/>
        <BooleanField source="isDisable" label={'Is deleted'}/>
      </Datagrid>
    </List>
  );
}

export default HelpList;
