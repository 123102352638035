import * as React from 'react';
import {cloneElement} from 'react';
import {
  BooleanField,
  CreateButton,
  Datagrid,
  DateField,
  downloadCSV,
  List,
  sanitizeListRestProps,
  TextField,
  TopToolbar,
  useListContext,
} from 'react-admin';
import {Card as MuiCard, CardContent, withStyles} from '@material-ui/core';
import moment from 'moment-timezone';

import {BooleanFilter, SearchFilter, StatusFilter} from './filters';

import CommonPagination from '../Components/paginations';
import _ from 'lodash';
import {unparse as convertToCSV} from 'papaparse';
import CustomExportButton from '../custom/CustomExportButton';

const Card = withStyles(theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      order: -1,
      width: '15em',
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))(MuiCard);

const FilterSidebar = () => {
  return (
      <Card>
        <CardContent>
          <SearchFilter/>
          <StatusFilter/>
          <BooleanFilter source={'isDisable'} label={'Is deleted'}/>
        </CardContent>
      </Card>
  );
};

const ContestStatusField = ({record, fromKey, toKey}) => {
  const sDate = moment(record[fromKey]);
  const eDate = moment(record[toKey]);
  const now = moment();
  
  let status, color;
  if (now.diff(sDate) >= 0 && now.diff(eDate) <= 0) {
    status = 'In progress';
    color = '#27cc7d';
  } else if (now.diff(sDate) > 0 && now.diff(eDate) > 0) {
    status = 'Done';
    color = '#9a9a9a';
  } else if (now.diff(sDate) < 0 && now.diff(eDate) < 0) {
    status = 'Reserved';
    color = '#cc981d';
  } else {
    status = '';
    color = '#cc214a';
  }
  
  return (
      <span style={{color: color}}>{status}</span>
  );
};

const myExporter = data => {
  const now = moment();
  _.forEach(data, (v, i) => {
    const sDate = moment(v.applyStartAt);
    const eDate = moment(v.applyEndAt);
    if (now.diff(sDate) >= 0 && now.diff(eDate) <= 0) {
      v.status = 'In progress';
    } else if (now.diff(sDate) > 0 && now.diff(eDate) > 0) {
      v.status = 'Done';
    } else if (now.diff(sDate) < 0 && now.diff(eDate) < 0) {
      v.status = 'Reserved';
    }
  });
  
  const csv = convertToCSV({
    data,
    fields: [
      'title',
      'applyStartAt',
      'applyEndAt',
      'tag',
      'contestType',
      'status',
      'applicantsCount',
      'cancelCount',
      'createAt',
      'isDisable'],
  });
  downloadCSV(csv, 'contests');
};

const ListActions = (props) => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    ...rest
  } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
    total,
  } = useListContext();
  return (
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {filters && cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
        <CreateButton basePath={basePath}/>
        <CustomExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filterValues={filterValues}
            maxResults={10}
            exporter={myExporter}
        />
      </TopToolbar>
  );
};

export const ContestList = props => {
  return (
      <List {...props} title="Contest List"
            aside={<FilterSidebar/>}
            actions={<ListActions/>}
            bulkActionButtons={false}
            pagination={<CommonPagination/>}
            filterDefaultValues={{isDisable: false}}
            sort={{field: 'createAt', order: 'DESC'}}>
        <Datagrid rowClick="edit">
          <TextField source="title" label={'Title'}/>
          <DateField source="applyStartAt" showTime label={'Apply Start'}/>
          <DateField source="applyEndAt" showTime label={'Apply End'}/>
          <TextField source="contestType" label={'Type'}/>
          <ContestStatusField label={'Status'} sortable={false}
                              fromKey={'applyStartAt'}
                              toKey={'applyEndAt'}
                              textAlign={'center'}/>
          
          <TextField source="applicantsCount" label={'Applicants'}
                     textAlign={'center'}/>
          <TextField source="cancelCount" label={'Canceled'}
                     textAlign={'center'}/>
          
          <DateField source="createAt" showTime label={'Create at'}/>
          <BooleanField source="isDisable" label={'Is deleted'}/>
        </Datagrid>
      </List>
  );
};

export default ContestList;
