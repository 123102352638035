import * as React from 'react';
import {
  List,
  Datagrid,
  BooleanField,
  DateField,
  ReferenceField,
  useListContext,
  downloadCSV,
  TopToolbar,
  sanitizeListRestProps,
  CreateButton,
} from 'react-admin';
import {
  Card as MuiCard,
  CardContent,
  withStyles,
} from '@material-ui/core';
import {Link as LinkIcon} from '@material-ui/icons';

import {
  SearchFilter,
  BooleanFilter,
} from './filters';

import CommonPagination from '../Components/paginations';
import {unparse as convertToCSV} from "papaparse";
import {cloneElement} from "react";
import CustomExportButton from "../custom/CustomExportButton";
import EllipsisTextField from "../custom/EllipsisTextField";

const Card = withStyles(theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      order: -1,
      width: '15em',
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))(MuiCard);

const FilterSidebar = () => {
  return (
      <Card>
        <CardContent>
          <SearchFilter/>
          <BooleanFilter source={'isDisable'}
                         label={'Is deleted'}/>
        </CardContent>
      </Card>
  );
};

const LinkToParent = () => (
    <span><LinkIcon/></span>
);

const myExporter = data => {
  const csv = convertToCSV({
    data,
    fields: ['comment', 'createAt', 'postPublicId', 'isDisable'],
  });
  downloadCSV(csv, 'help-comments');
};

const ListActions = (props) => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    ...rest
  } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
    total,
  } = useListContext();
  return (
          <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
              resource,
              showFilter,
              displayedFilters,
              filterValues,
              context: 'button',
            })}
            <CreateButton basePath={basePath} />
            <CustomExportButton
                    disabled={total === 0}
                    resource={resource}
                    sort={currentSort}
                    filterValues={filterValues}
                    maxResults={10}
                    exporter={myExporter}
            />
          </TopToolbar>
  );
};

export const HelpCommentList = props => {
  return (
      <List {...props} title="Help Comments"
            aside={<FilterSidebar/>}
            actions={<ListActions/>}
            bulkActionButtons={false}
            pagination={<CommonPagination/>}
            filterDefaultValues={{isDisable: false}}
            sort={{field: 'createAt', order: 'DESC'}}>
        <Datagrid rowClick="edit">
          <EllipsisTextField source="comment" options={{
            lineMaxLen: 50,
            maxLine: 3
          }} style={{whiteSpace: 'pre-wrap'}} />
          <DateField source="createAt" showTime label={'Create at'}/>
          <ReferenceField label="Linked help" reference="helps"
                          source="postPublicId" sortable={false} textAlign={'center'}>
            <LinkToParent/>
          </ReferenceField>
          <BooleanField source="isDisable" label={'Is deleted'}/>
        </Datagrid>
      </List>
  );
}

export default HelpCommentList;
