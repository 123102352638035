import * as React from 'react';
import { Filter, SearchInput, BooleanInput, } from 'react-admin';

export const SearchFilter = props => (
    <Filter {...props}>
      <SearchInput source="q" alwaysOn/>
    </Filter>
);

export const BooleanFilter = (props) => {
  return (
      <Filter {...props}>
        <BooleanInput alwaysOn {...props} />
      </Filter>
  )
};
