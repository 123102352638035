import firebase from 'firebase/app';
import 'firebase/auth';

import config from '../properties/fbCofig';

import axios from './axios';

firebase.initializeApp(config);
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    user.getIdToken().then((idToken) => {
      axios.defaults.headers.common['Authorization'] = `Bearer ${idToken}`;
    }, (error) => {
      console.error(error);
    });
  } else {
    const headers = Object.assign({}, axios.defaults.headers.common);
    delete headers['Authorization'];

    axios.defaults.headers.common = headers;
  }
});

export default firebase;
