import http from "../http-common";

class AccountService {
  signIn(email, password) {
    return http.post("/accounts/signIn", {email, password});
  }

  signOut(accountPublicId) {
    return http.post(`/accounts/${accountPublicId}/signOut`, {});
  }
}

export default new AccountService();
