import * as React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  ImageInput,
  ImageField,
  SelectInput,
  NumberInput,
  DateTimeInput,
} from 'react-admin';
import {CustomPageTitle} from '../Layouts';

export const BannerCreate = (props) => {
  return (
    <Create {...props} title={<CustomPageTitle title={'New Banner'} {...props}/>}>
      <SimpleForm>
        <DateTimeInput source="validStartAt" validate={[required()]}  />
        <DateTimeInput source="validEndAt" validate={[required()]} />
        <ImageInput source="appImageUrl" label="App Image" accept="image/*"
                    placeholder={'Upload Banner Image'} validate={[required()]}>
          <ImageField source="src" title="title"/>
        </ImageInput>
        <ImageInput source="desktopWebImageUrl" label="Web Image - Desktop"
                    accept="image/*" placeholder={'Upload Banner Image'}
                    validate={[required()]}>
          <ImageField source="src" title="title"/>
        </ImageInput>
        <ImageInput source="mobileWebImageUrl" label="Web Image - Mobile"
                    accept="image/*" placeholder={'Upload Banner Image'}
                    validate={[required()]}>
          <ImageField source="src" title="title"/>
        </ImageInput>
        <SelectInput source="position" choices={[
          {
            text: '(not set)',
            value: null,
          },
          {
            text: 'HOME_MAIN',
            value: 'HOME_MAIN',
          },
        ]} optionText={'text'} optionValue={'value'} validate={[required()]}/>
        <TextInput source="deepLinkUrl" fullWidth />
        <TextInput source="webLinkUrl" fullWidth />
        <NumberInput source="sequenceNumber" />
      </SimpleForm>
    </Create>)
};

export default BannerCreate;
