import * as React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  Loading,
} from 'react-admin';
import {CustomPageTitle} from '../Layouts';
import {parse} from 'query-string';
import CommentReferenceInput from './CommentReferenceInput'
import {trimmedMinLength} from "../custom/customValidate";

export const NoticeCommentCreate = (props) => {
  const {noticeId: notice_id_string} = parse(props.location.search);
  const noticeId = notice_id_string ? notice_id_string : null;

  return (props.loading) ? <Loading/> : (
      <Create {...props}
              title={<CustomPageTitle title={'New Notice Comment'} {...props}/>}>
        <SimpleForm undoable={false} defaultValue={{noticeId: noticeId}}>
          <CommentReferenceInput
                  source="noticeId"
                  reference="notices"
                  validate={required()}
                  perPage={100}
          />
          <TextInput source="comment" multiline fullWidth validate={[required(), trimmedMinLength(1)]}/>
        </SimpleForm>
      </Create>
  );
};

export default NoticeCommentCreate;
