import * as React from "react";
import {
	List,
	Datagrid,
	TextField,
	DateField,
	useListContext,
	ReferenceField,
	downloadCSV,
	TopToolbar,
	sanitizeListRestProps,
} from "react-admin";
import { Card as MuiCard, CardContent, withStyles } from "@material-ui/core";
import {
	Check as ConfirmedIcon,
	EmojiEvents as AwardedIcon,
} from "@material-ui/icons";

import { SearchFilter, ReferenceAutoComplete, SelectFilter } from "./filters";

import CommonPagination from "../Components/paginations";
import { unparse as convertToCSV } from "papaparse";
import { cloneElement } from "react";
import CustomExportButton from "../custom/CustomExportButton";

const Card = withStyles((theme) => ({
	root: {
		[theme.breakpoints.up("sm")]: {
			order: -1,
			width: "15em",
			marginRight: "1em",
		},
		[theme.breakpoints.down("sm")]: {
			display: "none",
		},
	},
}))(MuiCard);

const FilterSidebar = () => {
	return (
		<Card>
			<CardContent>
				<SearchFilter />
				<ReferenceAutoComplete
					label="Contest"
					reference="contests"
					referenceSource="autoComplete/contests"
					source="contestId"
					optionText="title"
				/>
				<ReferenceAutoComplete
					label="Novel"
					reference="novels"
					referenceSource="autoComplete/novels"
					source="novelId"
					optionText="title"
				/>
				<ReferenceAutoComplete
					label="Author"
					reference="users"
					referenceSource="autoComplete/users"
					source="authorId"
					optionText="displayName"
				/>
				<SelectFilter
					choices={[
						{
							text: "Yes",
							value: true,
						},
						{
							text: "No",
							value: false,
						},
					]}
					label="Is Awarded"
					icon={<AwardedIcon />}
					source={"isAwarded"}
				/>
			</CardContent>
		</Card>
	);
};

const CheckField = ({ record, source, icon, htmlColor }) => {
	const confirmed = record[source];
	const Icon = !icon ? () => <ConfirmedIcon /> : () => icon;

	if (!confirmed) {
		return <span />;
	}
	return (
		<span style={{ color: htmlColor || "inherit" }}>
			<Icon />
		</span>
	);
};

const myExporter = (data) => {
	const csv = convertToCSV({
		data,
		fields: [
			"contestTitle",
			"novelTitle",
			"authorUserDisplayName",
			"genreName",
			"novelCreateAt",
			"isAwarded",
			"applyAt",
		],
	});
	downloadCSV(csv, "contest-applicants");
};

const ListActions = (props) => {
	const { className, exporter, filters, maxResults, ...rest } = props;
	const {
		currentSort,
		resource,
		displayedFilters,
		filterValues,
		showFilter,
		total,
	} = useListContext();
	return (
		<TopToolbar className={className} {...sanitizeListRestProps(rest)}>
			{filters &&
				cloneElement(filters, {
					resource,
					showFilter,
					displayedFilters,
					filterValues,
					context: "button",
				})}
			<CustomExportButton
				disabled={total === 0}
				resource={resource}
				sort={currentSort}
				filterValues={filterValues}
				maxResults={10}
				exporter={myExporter}
			/>
		</TopToolbar>
	);
};

export const ContestApplicantList = (props) => {
	return (
		<List
			{...props}
			title="Contest Applicant List"
			aside={<FilterSidebar />}
			actions={<ListActions />}
			bulkActionButtons={false}
			pagination={<CommonPagination />}
			sort={{ field: "applyAt", order: "DESC" }}
		>
			<Datagrid rowClick={"edit"}>
				<ReferenceField
					reference="contests"
					source="contestId"
					label="Contest Title"
					sortBy="createAt"
					sortable={false}
				>
					<TextField source="title" />
				</ReferenceField>
				<ReferenceField
					reference="novels"
					source="novelId"
					label="Novel Title"
					sortBy="createAt"
					sortable={false}
				>
					<TextField source="title" />
				</ReferenceField>
				<ReferenceField
					reference="users"
					source="authorId"
					label="Author"
					sortBy="createAt"
					sortable={false}
				>
					<TextField source="displayName" />
				</ReferenceField>

				<TextField source="genreName" label={"Genre"} />

				<DateField source="novelCreateAt" label={"Novel Created"} showTime />

				<CheckField
					source="isAwarded"
					textAlign="center"
					icon={<AwardedIcon />}
					htmlColor={"#f5b423"}
				/>

				<DateField source="applyAt" showTime />
			</Datagrid>
		</List>
	);
};

export default ContestApplicantList;
