import * as React from 'react';
import {
  BooleanField,
  BooleanInput,
  Datagrid,
  DateField,
  Edit,
  FormTab,
  Labeled,
  Pagination,
  ReferenceManyField,
  required,
  SaveButton,
  TabbedForm,
  TextField,
  TextInput,
  Toolbar,
} from 'react-admin';
import {Box, Button, Card, CardContent} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {CustomPageTitle} from '../Layouts';
import {Comment as CommentIcon} from '@material-ui/icons';
import {Link} from 'react-router-dom';
import EllipsisTextField from '../custom/EllipsisTextField';
import RichTextInput from 'ra-input-rich-text';

const useCustomToolbarStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const CustomCommentButton = ({record}) => (
    <Button color={'primary'} type={'button'} variant={'contained'}
            startIcon={<CommentIcon/>} component={Link} to={{
      pathname: '/notice-comments/create',
      search: `?noticeId=${record.id}`,
    }}>
      Comment
    </Button>
);

const CustomNoticeToolbar = props => {
  const isChanged = !props.pristine;
  
  return (
      <Toolbar {...props} classes={useCustomToolbarStyles()}>
        <SaveButton disabled={!isChanged}/>
        <CustomCommentButton/>
      </Toolbar>
  );
};

const LinkInfoView = ({record, ...props}) => {
  const postId = record.id;
  const boardPublicId = record.boardPublicId;
  return (
      <Card variant={'outlined'}>
        <CardContent>
          <Labeled label={'APP LINK'}>
            <Box p={1} style={{color: '#555'}}>
              {process.env.REACT_APP_DEEP_LINK_BASE}?boardPublicId={boardPublicId}&postPublicId={postId}
            </Box>
          </Labeled>
          <hr/>
        </CardContent>
      </Card>
  
  );
};

export const NoticeEdit = (props) => (
    <Edit {...props} mutationMode="optimistic"
          title={<CustomPageTitle source={'title'} {...props} />}>
      <TabbedForm undoable={false} toolbar={<CustomNoticeToolbar/>}>
        <FormTab label="Info">
          <TextField source="id"/>
          <TextInput source="title" fullWidth validate={[required()]}/>
          <RichTextInput source="content"
                         toolbar={[
                           ['bold', 'italic', 'underline'],
                           [{'align': []}],
                           [{'indent': '-1'}, {'indent': '+1'}],
                           ['blockquote', 'image']]}
                         fullWidth/>
          <DateField source="createAt" showTime/>
          <BooleanInput label={'Is deleted'} source="isDisable"/>
          <LinkInfoView/>
        </FormTab>
        <FormTab label="Comments" path="comments">
          <ReferenceManyField label={''} reference="notice-comments"
                              target="noticeId" fullWidth
                              perPage={10} pagination={<Pagination/>}
                              sort={{field: 'createAt', order: 'DESC'}}>
            <Datagrid rowClick="show" style={{width: '100%'}}>
              <EllipsisTextField source="comment" options={{
                lineMaxLen: 50,
                maxLine: 3,
              }} style={{whiteSpace: 'pre-wrap'}}/>
              <DateField source="createAt" showTime={false}/>
              <BooleanField label={'Is deleted'} source="isDisable"/>
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
      </TabbedForm>
    </Edit>
);

export default NoticeEdit;