import * as React from 'react';
import {
  FilterList,
  FilterListItem,
  Filter,
  SearchInput,
  DateInput,
  BooleanInput,
  SelectInput,
} from 'react-admin';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import {
  Adjust as StatusIcon,
  TrackChanges as TargetIcon,
    Category as CategoryIcon,
} from '@material-ui/icons';
import {Box} from '@material-ui/core';

import genres from './genres';

export const SearchFilter = props => (
    <Filter {...props}>
      <SearchInput source="q" alwaysOn/>
    </Filter>
);

export const GenreFilter = props => (
    <FilterList label={'Genre'} icon={<CategoryIcon/>}>
      <Filter {...props} style={{minWidth:'100%'}}>
        <SelectInput source={'genreCode'} choices={genres} optionText={'name'}
                     optionValue={'code'} emptyText={'(not set)'} fullWidth alwaysOn/>
      </Filter>
    </FilterList>
);

export const CreateAtFilter = () => (
    <FilterList label={'create at'} icon={<AccessTimeIcon/>}>
      <Box mt={1}>
        <Filter>
          <DateInput label={'from'} source={'createAt_gte'} alwaysOn/>
          <DateInput label={'to'} source={'createAt_lte'} alwaysOn/>
        </Filter>
      </Box>
    </FilterList>
);

export const ChapterUpdateAtFilter = () => (
    <FilterList label={'episode update at'} icon={<AccessTimeIcon/>}>
      <Box mt={1}>
        <Filter>
          <DateInput label={'from'} source={'lastChapterUpdateAt_gte'} alwaysOn/>
          <DateInput label={'to'} source={'lastChapterUpdateAt_lte'} alwaysOn/>
        </Filter>
      </Box>
    </FilterList>
);

export const SerializingStatusFilter = () => (
    <FilterList label="Status" icon={<StatusIcon/>}>
      <FilterListItem label={'(not set)'} value={{
        serializingStatusCode: null,
      }}/>
      <FilterListItem label={'Serializing'} value={{
        serializingStatusCode: 'SERIALIZING',
      }}/>
      <FilterListItem label={'Completed'} value={{
        serializingStatusCode: 'COMPLETE',
      }}/>
      <FilterListItem label={'Pause'} value={{
        serializingStatusCode: 'PAUSE',
      }}/>
    </FilterList>
);

export const TargetFilter = () => (
    <FilterList label="Target" icon={<TargetIcon/>}>
      <FilterListItem label={'(not set)'} value={{
        targetReaderSegmentCode: null,
      }}/>
      <FilterListItem label={'ALL'} value={{
        targetReaderSegmentCode: 'ALL',
      }}/>
      <FilterListItem label={'Adult Only'} value={{
        targetReaderSegmentCode: 'ADULT',
      }}/>
    </FilterList>
);

export const IsPublicFilter = (props) => {
  console.log('IsPublicFilter:props', props);
  return (
      <Filter {...props}>
        <BooleanInput source={'isPublic'} label={'is public'}
                      alwaysOn {...props} />
      </Filter>
  );
};

export const BooleanFilter = (props) => {
  return (
      <Filter {...props}>
        <BooleanInput alwaysOn {...props} />
      </Filter>
  );
};

