import * as React from 'react';
import {
  Edit,
  TabbedForm,
  FormTab,
  TextField,
  Toolbar,
  SaveButton,
  TextInput,
  SelectInput,
  DateField,
  required,
} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';
import {CustomPageTitle} from '../Layouts';

const useCustomToolbarStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});



const CustomCommunityToolbar = props => {
  const isChanged = !props.pristine;

  return (
      <Toolbar {...props} classes={useCustomToolbarStyles()}>
        <SaveButton disabled={!isChanged}/>
      </Toolbar>
  );
};

export const BookReviewEdit = (props) => (
    <Edit {...props} mutationMode="optimistic" title={<CustomPageTitle source={'title'} {...props} />}>
      <TabbedForm undoable={false} toolbar={<CustomCommunityToolbar/>}>
        <FormTab label="Info">
          <TextField source="id"/>
          <TextField source="title" fullWidth validate={[required()]}/>
          <TextField source="content" multiline fullWidth
                     validate={[required()]}/>
          <DateField source="createAt" showTime/>
          <TextField source="typeCode" label = "Category"/>
          <TextField source="viewCount"/>
          <TextField source="commentCount"/>
          <SelectInput label={'Status'} source="isDisable" choices={[{
            text: 'Active',
            value: false,
          },
          {
            text: 'Inactive',
            value: true,
          }]} optionText={'text'} optionValue={'value'} validate={[required()]}
          />
        </FormTab>
      </TabbedForm>
    </Edit>
);

export default BookReviewEdit;
