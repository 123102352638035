class ProcessAuth {
  constructor(props) {
    this.connectUserStorage = window[`${props || 'local'}Storage`];
  }

  #checkType = (data) => {
    return Object.prototype.toString.call(data).slice(8,-1);
  };

  init(props) {
    this.clearAuth();
    this.connectUserStorage = window[`${props || 'local'}Storage`];
  }

  setUser(props) {
    if (this.#checkType(props) !== 'String') props = JSON.stringify(props);
    this.connectUserStorage.setItem('user', props);
  }

  setToken(token) {
    this.connectUserStorage.setItem('token', token);
  }

  getUser() {
    return JSON.parse(this.connectUserStorage.getItem('user'));
  }

  getToken() {
    return this.connectUserStorage.getItem('token');
  }

  getAuth() {
    const data = this.getUser();
    return {
      accountId: data.accountPublicId,
      userId: data.userPublicId,
    }
  }
  getPermission() {
    const data = this.getUser();
    return {
      role: data.roleCode,
    }
  }

  checkAuth() {
    return !!this.connectUserStorage.getItem('user');
  }
  clearAuth() {
    this.connectUserStorage.removeItem('user');
    this.connectUserStorage.removeItem('token');
  }
}

export default ProcessAuth;
