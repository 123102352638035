import * as React from 'react';
import { FilterList, Filter, SearchInput, BooleanInput, SelectInput,} from 'react-admin';

export const SearchFilter = props => (
    <Filter {...props}>
      <SearchInput source="q" alwaysOn/>
    </Filter>
);

export const SelectFilter = (props) => {
  return (
      <FilterList {...props}>
        <Filter>
          <SelectInput {...props} optionValue={'value'} optionText={'text'} emptyText={'(not set)'} emptyValue={null} label={''} alwaysOn/>
        </Filter>
      </FilterList>
  )
};


export const BooleanFilter = (props) => {
  return (
      <Filter {...props}>
        <BooleanInput alwaysOn {...props} />
      </Filter>
  )
};