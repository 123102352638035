import * as React from 'react';
import {
  Create,
  DateTimeInput,
  maxLength,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import {CustomPageTitle} from '../Layouts';
import {Grid} from '@material-ui/core';
import RichTextInput from 'ra-input-rich-text';

const CustomGrid = ({basePath, ...props}) => (
    <Grid {...props} style={{width: '100%'}}/>
);

export const ContestCreate = (props) => (
    <Create {...props}
            title={<CustomPageTitle title={'New Contest'} {...props}/>}>
      <SimpleForm mutationMode="optimistic">
        <TextInput source="title" autoFocus fullWidth
                   validate={[required(), maxLength(100)]}/>
        <RichTextInput source="content"
                       label="Contest Information"
                       toolbar={[
                         ['bold', 'italic', 'underline'],
                         [{'align': []}],
                         [{'indent': '-1'}, {'indent': '+1'}],
                         ['blockquote', 'image']]}
                       fullWidth/>
        <CustomGrid container spacing={4}>
          <Grid item>
            <DateTimeInput source="applyStartAt" validate={[required()]}/>
          </Grid>
          <Grid item>
            <DateTimeInput source="applyEndAt" validate={[required()]}/>
          </Grid>
        </CustomGrid>
        <CustomGrid container spacing={4}>
          <Grid item>
            <DateTimeInput source="viewStartAt" validate={[required()]}/>
          </Grid>
          <Grid item>
            <DateTimeInput source="viewEndAt" validate={[required()]}/>
          </Grid>
        </CustomGrid>
        <SelectInput source="contestType" choices={[
          {
            text: 'MONTHLY',
            value: 'MONTHLY',
          },
          {
            text: 'WEEKLY',
            value: 'WEEKLY',
          },
          {
            text: 'EVENT',
            value: 'EVENT',
          },
        ]} optionText={'text'} optionValue={'value'} validate={[required()]}
                     defaultValue={'MONTHLY'}/>
        <TextInput source="landingUrl" fullWidth validate={[required()]}/>
        <RichTextInput source="rule"
                       label="Contest Rules"
                       toolbar={[
                         ['bold', 'italic', 'underline'],
                         [{'align': []}],
                         [{'indent': '-1'}, {'indent': '+1'}],
                         ['blockquote', 'image']]}
                       fullWidth/>
      </SimpleForm>
    </Create>
);

export default ContestCreate;
