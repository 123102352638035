import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  useListContext,
  downloadCSV,
  TopToolbar,
  sanitizeListRestProps,
  BooleanField,
  FunctionField,
} from 'react-admin';
import {
  Card as MuiCard,
  CardContent,
  withStyles,
} from '@material-ui/core';

import {
  Security as SecretIcon,
  DeviceHub as TypeIcon,
  Visibility as VisibilityOnIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@material-ui/icons';

import {
  SearchFilter,
  BooleanFilter,
} from './filters';

import CommonPagination from '../Components/paginations';
import {unparse as convertToCSV} from "papaparse";
import {cloneElement} from "react";
import CustomExportButton from "../custom/CustomExportButton";
import EllipsisTextField from "../custom/EllipsisTextField";

const Card = withStyles(theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      order: -1,
      width: '15em',
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))(MuiCard);

const FilterSidebar = () => {
  return (
    <Card>
      <CardContent>
        <SearchFilter />
        <BooleanFilter label={'is deleted'} source={'isDisable'}/>
      </CardContent>
    </Card>
  );
};

const myExporter = data => {
  const csv = convertToCSV({
    data,
    fields: ['title', 'typeCode', 'createAt', 'progress', 'isSecret', 'isDisable'],
  });
  downloadCSV(csv, 'help');
};

const ListActions = (props) => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    ...rest
  } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    showFilter,
    total,
  } = useListContext();
  return (
          <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
              resource,
              showFilter,
              displayedFilters,
              filterValues,
              context: 'button',
            })}
            <CustomExportButton
                    disabled={total === 0}
                    resource={resource}
                    sort={currentSort}
                    filterValues={filterValues}
                    maxResults={10}
                    exporter={myExporter}
            />
          </TopToolbar>
  );
};

export const BookReviewList = props => {
  return (
    <List {...props} title="Book Review"
          aside={<FilterSidebar/>}
          actions={<ListActions/>}
          bulkActionButtons={false}
          pagination={<CommonPagination/>}
          filterDefaultValues={{isDisable: false}}
          sort={{field: 'createAt', order: 'DESC'}}>
      <Datagrid rowClick="edit">
        <EllipsisTextField source="title" label={'Title'} maxLen={30} sortable={false}/>
        <TextField source="novelTitle" label={'Novel'} sortable={false}/>
        <ReferenceField label={'Username'} reference="users" source="writerUserPublicId" sortable={false}>
            <TextField source="displayName" />
        </ReferenceField>
        <DateField source="createAt" showTime label={'Create at'}/>
        <TextField source="commentCount" label={"Comments"}/>
        <BooleanField source="isDisable" label={'Is deleted'}/>
      </Datagrid>
    </List>
  );
}

export default BookReviewList;
