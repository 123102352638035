import * as React from 'react';
import {
  BooleanInput,
  Datagrid,
  DateField,
  DateTimeInput,
  Edit,
  FormTab,
  Labeled,
  maxLength,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  required,
  SaveButton,
  SelectInput,
  TabbedForm,
  TextField,
  TextInput,
  Toolbar,
} from 'react-admin';
import {Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {CustomPageTitle} from '../Layouts';
import moment from 'moment-timezone';
import RichTextInput from 'ra-input-rich-text';

const useCustomToolbarStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const DoNotDeleteToolbar = props => {
  const isChanged = !props.pristine;
  
  return (
      <Toolbar {...props} classes={useCustomToolbarStyles()}>
        <SaveButton disabled={!isChanged}/>
      </Toolbar>
  );
};

const CustomGrid = ({basePath, ...props}) => (
    <Grid {...props} style={{width: '100%'}}/>
);

const ContestDetailStatusField = ({record}) => {
  const sDate = moment(record.applyStartAt);
  const eDate = moment(record.applyEndAt);
  const now = moment();
  
  let status, color;
  if (now.diff(sDate) >= 0 && now.diff(eDate) <= 0) {
    status = 'In progress';
    color = '#27cc7d';
  } else if (now.diff(sDate) > 0 && now.diff(eDate) > 0) {
    status = 'Done';
    color = '#9a9a9a';
  } else if (now.diff(sDate) < 0 && now.diff(eDate) < 0) {
    status = 'Reserved';
    color = '#cc981d';
  } else {
    status = '';
    color = '#cc214a';
  }
  
  return (
      <Labeled label="Status">
        <span style={{color: color}}>{status}</span>
      </Labeled>
  );
};

export const ContestEdit = (props) => (
    <Edit {...props} mutationMode="optimistic"
          title={<CustomPageTitle source={'title'} {...props} />}>
      <TabbedForm toolbar={<DoNotDeleteToolbar/>}>
        <FormTab label={'Info'}>
          <TextField source="id"/>
          
          <TextInput source="title" autoFocus fullWidth
                     validate={[required(), maxLength(100)]}/>
          <RichTextInput source="content"
                         label="Contest Information"
                         toolbar={[
                           ['bold', 'italic', 'underline'],
                           [{'align': []}],
                           [{'indent': '-1'}, {'indent': '+1'}],
                           ['blockquote', 'image']]}
                         fullWidth/>
          
          <ContestDetailStatusField/>
          
          <CustomGrid container spacing={4}>
            <Grid item>
              <DateTimeInput source="applyStartAt" validate={[required()]}/>
            </Grid>
            <Grid item>
              <DateTimeInput source="applyEndAt" validate={[required()]}/>
            </Grid>
          </CustomGrid>
          <CustomGrid container spacing={4}>
            <Grid item>
              <DateTimeInput source="viewStartAt" validate={[required()]}/>
            </Grid>
            <Grid item>
              <DateTimeInput source="viewEndAt" validate={[required()]}/>
            </Grid>
          </CustomGrid>
          
          <SelectInput source="contestType" choices={[
            {
              text: 'MONTHLY',
              value: 'MONTHLY',
            },
            {
              text: 'WEEKLY',
              value: 'WEEKLY',
            },
            {
              text: 'EVENT',
              value: 'EVENT',
            },
          ]} optionText={'text'} optionValue={'value'} validate={[required()]}
                       defaultValue={'MONTHLY'}/>
          
          <TextInput source="landingUrl" fullWidth validate={[required()]}/>
          
          <RichTextInput source="rule"
                         label="Contest Rules"
                         toolbar={[
                           ['bold', 'italic', 'underline'],
                           [{'align': []}],
                           [{'indent': '-1'}, {'indent': '+1'}],
                           ['blockquote', 'image']]}
                         fullWidth/>
          
          <DateField source="createAt" showTime/>
          <BooleanInput label={'Is deleted'} source="isDisable"/>
        </FormTab>
        
        <FormTab label={'Applicants'}>
          <ReferenceManyField label={''} reference="contest-applicants"
                              target="contestId" fullWidth
                              perPage={10} pagination={<Pagination
              rowsPerPageOptions={[10, 30, 50, 100]}/>}
                              sort={{field: 'applyAt', order: 'DESC'}}>
            <Datagrid rowClick="edit">
              
              <TextField source="novelTitle" sortable={false}/>
              
              <TextField label="authorName" sortable={false}
                         source="authorUserDisplayName"/>
              
              <ReferenceField reference="novels" source="novelId" label="Genre"
                              link={false} sortable={false}>
                <TextField source="genreName"/>
              </ReferenceField>
              <TextField label="Chapter cnt." source="chapterCount"/>
              <DateField source="applyAt" showTime/>
              <ReferenceField reference="novels" source="novelId"
                              label="Publishing"
                              link={false} sortable={false}>
                <TextField source="isPublic"/>
              </ReferenceField>
              <TextField label="Mature" source="targetReaderSegment"/>
              <TextField label="Country" source="signUpGeoCountry"/>
            
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
        
        <FormTab label={'Awards'}>
          <ReferenceManyField label={''} reference="contest-applicants"
                              target="contestId" fullWidth
                              perPage={10}
                              pagination={<Pagination
                                  rowsPerPageOptions={[10, 30, 50, 100]}/>}
                              filter={{isAwarded: true}}
                              sort={{field: 'applyAt', order: 'DESC'}}>
            <Datagrid rowClick="edit">
              
              <TextField source="novelTitle"/>
              
              <TextField label="authorName" source="authorUserDisplayName"/>
              <TextField label="Chapter cnt." source="chapterCount"/>
              <></>
              
              <DateField source="applyAt" showTime/>
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
      
      </TabbedForm>
    </Edit>
);

export default ContestEdit;
