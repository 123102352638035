import axios from 'axios';
import ProcessAuth from "../utils/ProcessAuth";

const authProcessor = new ProcessAuth(process.env.REACT_APP_USER_STATE_SCOPE);

axios.defaults.headers.common['Authorization'] = authProcessor.getToken();
axios.defaults.headers.common['X-Joara-Device-Type'] = 'DESKTOP_WEB';
axios.defaults.headers.common['X-Joara-Display-Language'] = 'EN';
axios.defaults.headers.common['X-Joara-Geo-Country'] = 'US';
axios.defaults.headers.common['X-Joara-Sales-Country'] = 'US';

axios.interceptors.request.use(config => {
    if (config.data instanceof FormData) {
        config.headers['Content-Type'] = 'multipart/form-data';
    }
    return config;
});

export default axios;
