import React, {useState} from 'react';
import {useFormState} from 'react-final-form';
import {
  ReferenceInput,
  SelectInput,
} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';

import QuickNoticeShowButton from './QuickNoticeShowButton';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
});

const spySubscription = {values: true};

const CommentReferenceInput = props => {
  const classes = useStyles();
  const [version, setVersion] = useState(0);
  const {values} = useFormState({subscription: spySubscription});

  return (
      <>
        {!!values.noticeId && <div style={{marginBottom: 5, color: '#666', fontSize: '0.85em'}}>Selected Notice: {values.noticeId}</div>}
        <div className={classes.root}>
          <ReferenceInput key={version} {...props} sort={{ field: 'createAt', order: 'DESC' }}>
            <SelectInput optionText="title" />
          </ReferenceInput>

          {!!values.noticeId && <QuickNoticeShowButton id={values.noticeId}/>}
        </div>
      </>
  );
};

export default CommentReferenceInput;
