import * as React from 'react';
import { useResourceContext, useLoading } from 'react-admin';
import { LinkToResourceBaseButton } from '../Components/Buttons';

const CustomPageTitle = ({record, source, title, ...props}) => {
  const resource = useResourceContext();
  const extraProps = props || {},
      basePath = extraProps.basePath || `/${resource}`,
      defaultTitle = extraProps.id ? `${resource} #${extraProps.id}` : '';

  const currentRecord = record || {},
      sourceTitle = currentRecord[source] ? `${resource} : #${currentRecord[source]}` : '';
  let currentTitle = title || sourceTitle || defaultTitle;
  if (useLoading()) return (<span>Loading...</span>);
  return (
      <span><LinkToResourceBaseButton basePath={basePath}/> {currentTitle}</span>
  );
};

export default CustomPageTitle;
