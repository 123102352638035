const genres = [
  { code: "001", name: "Horror" },
  { code: "003", name: "Fanfiction" },
  { code: "004", name: "Sports" },
  { code: "005", name: "Paranormal" },
  { code: "006", name: "Science Fiction" },
  { code: "007", name: "Adventure" },
  { code: "008", name: "Teen Fiction" },
  { code: "009", name: "Poetry" },
  { code: "012", name: "Other" },
  { code: "013", name: "Fantasy" },
  { code: "014", name: "Romance" },
  { code: "015", name: "Mystery" },
  { code: "016", name: "Thriller" },
  { code: "017", name: "Humor" },
  { code: "018", name: "Historical Fiction" },
  { code: "019", name: "General Fiction" },
  { code: "020", name: "LGBTQ+" },
  { code: "021", name: "New Adult" },
  { code: "022", name: "Non-Fiction" },
  { code: "023", name: "Vampire" },
  { code: "024", name: "WereWol" },
];

export default genres;
