import axios from "axios";
import ProcessAuth from "./utils/ProcessAuth";
import Constants from "./constants/Constants";
const authProcessor = new ProcessAuth(process.env.REACT_APP_USER_STATE_SCOPE);

export default axios.create({
  baseURL: `${Constants.apiUrl}`,
  headers: {
    'Content-type': 'application/json',
    'Authorization': authProcessor.getToken(),
    'X-Joara-Device-Type': 'DESKTOP_WEB',
    'X-Joara-Display-Language': 'EN',
    'X-Joara-Geo-Country': 'US',
    'X-Joara-Sales-Country': 'US'
  }
});
