import * as React from "react";
import {
	List,
	Datagrid,
	TextField,
	ChipField,
	BooleanField,
	DateField,
	ReferenceField,
	ImageField,
	Pagination,
	useListContext,
	downloadCSV,
	TopToolbar,
	sanitizeListRestProps,
} from "react-admin";
import { Card as MuiCard, CardContent, withStyles } from "@material-ui/core";

import { SearchFilter, ReferenceAutoComplete, SelectFilter } from "./filters";




const Card = withStyles((theme) => ({
	root: {
		[theme.breakpoints.up("sm")]: {
			order: -1,
			width: "15em",
			marginRight: "1em",
		},
		[theme.breakpoints.down("sm")]: {
			display: "none",
		},
	},
}))(MuiCard);

const FilterSideBar = () => {
	return (
		<Card>
			<CardContent>
				<SearchFilter />
				<ReferenceAutoComplete
					label="Author"
					reference="users"
					referenceSource="autoComplete/users"
					source="authorId"
					optionText="displayName"
				/>
				<ReferenceAutoComplete
					label="Email"
					reference="users"
					referenceSource="autoComplete/users"
					source="authorId"
					optionText="email"
				/>
				<SelectFilter
					choices={[
						{
							text: "Sun",
							value: "SUN",
						},
						{
							text: "Mon",
							value: "MON",
						},
						{
							text: "Tue",
							value: "TUE",
						},
						{
							text: "Wed",
							value: "WED",
						},
						{
							text: "Thu",
							value: "THU",
						},
						{
							text: "Fri",
							value: "FRI",
						},
						{
							text: "Sat",
							value: "SAT",
						},
					]}
					label="Day of Week"
					source={"dayOfWeek"}
				/>
			</CardContent>
		</Card>
	);
};

export const JoaraBooksList = (props) => {
	console.log(props);
	return (
		<List
			{...props}
			title="List of Joara Books"
			bulkActionButtons={false}
			aside={<FilterSideBar />}
		>
			<Datagrid basePath="novel" rowClick={(id)=>`novels/${id}`}>
				<TextField source="title" title={"Title"} sortable={false} />
				<TextField source="email" title={"Email"} sortable={false} />
				<ReferenceField label={'Username'} reference="users" source="authorUserPublicId" sortable={false}>
            <TextField source="displayName" />
        </ReferenceField>
				<DateField source="registration" label={"Registration"} />
				<TextField
					source="serializingStatus"
					title={"Status"}
					sortable={true}
				/>
				<TextField
					source="dayOfWeeks"
					title={"Days of Week"}
					sortable={false}
				/>
				<DateField source="contractFinishes" label={"Contract Finished"} />
			</Datagrid>
		</List>
	);
};

export default JoaraBooksList;
