import * as React from 'react';
import {
  Show,
  List,
  Datagrid,
  TextField,
  BooleanField,
  DateField,
  downloadCSV,
  useListContext,
  TopToolbar,
  sanitizeListRestProps,
  CreateButton
} from 'react-admin';
import {
  Card as MuiCard,
  CardContent,
  withStyles,
    makeStyles,
  Box,
} from '@material-ui/core';
import {Check as ConfirmedIcon} from '@material-ui/icons';
import moment from 'moment-timezone';
import _ from 'lodash';
import {
  StatusFilter,
  BooleanFilter,
} from './filters';

import CommonPagination from '../Components/paginations';
import {unparse as convertToCSV} from "papaparse";
import {cloneElement} from "react";
import CustomExportButton from "../custom/CustomExportButton";

const Card = withStyles(theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      order: -1,
      width: '15em',
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))(MuiCard);

const useStyles = makeStyles({
  preview: {
    maxWidth: 500,
    maxHeight: 200,
  },
});

const FilterSidebar = () => {
  return (
      <Card>
        <CardContent>
          <StatusFilter/>
          <BooleanFilter source={'isDisable'} label={'Is deleted'}/>
        </CardContent>
      </Card>
  );
};

const BannerViewField = ({record, source}) => {
  const bannerPath = source ? record[source] : record.appImageUrl;
  const bannerImageUrl = `${bannerPath}`;
  const bannerTitle = record.title || '';
  return (
      <div>
        <img src={bannerImageUrl} alt={bannerTitle} title={bannerTitle}
             style={{maxWidth: 180, maxHeight: 70}}/>
      </div>
  );
};

const BannerStatusField = ({record}) => {
  const sDate = moment(record.validStartAt);
  const eDate = moment(record.validEndAt);
  const now = moment();

  let status, color;
  if (now.diff(sDate) >= 0 && now.diff(eDate) <= 0) {
    status = 'In progress';
    color = '#27cc7d';
  } else if (now.diff(sDate) > 0 && now.diff(eDate) > 0) {
    status = 'Done';
    color = '#9a9a9a';
  } else if (now.diff(sDate) < 0 && now.diff(eDate) < 0) {
    status = 'Reserved';
    color = '#cc981d';
  } else {
    status = '';
    color = '#cc214a';
  }

  return (
      <span style={{color: color}}>{status}</span>
  );
};

const CheckLinkField = ({record, source}) => {
  if (!record[source]) {
    return <span/>;
  }
  if (record[source]) {
    return (
        <span><ConfirmedIcon htmlColor={'#2bcc81'}/></span>
    );
  }
};

const CustomBox = ({basePath, ...props}) => (
    <Box {...props} />
);
const BannerImagesShow = props => {
  const Actions = () => (<h2>Banner Image Preview</h2>);
  const PreviewContainer = ({source, label, record}) => {
    const imagePath = record[source] || '';
    if (!imagePath) return (
        <Box display={'block'} mb={3}>
          <div>{label}</div>
          <div>No Image Url</div>
        </Box>
    );
    return (
        <Box display={'block'} mb={3}>
          <div>{label}</div>
          <img src={`${imagePath}`} className={classes.preview} onLoad={handleImageLoad}/>
          <span className={'sizeview'} style={{marginLeft: '1em'}} />
        </Box>
    )
  };
  const handleImageLoad = (ev) => {
    const image = ev.target;
    const sizeDom = image.parentNode.querySelector('.sizeview');
    console.log(image.naturalWidth, image.naturalHeight)
    sizeDom.innerText = `${image.naturalWidth} x ${image.naturalHeight}`;
  };
  const classes = useStyles();
  return (
      <Show {...props} actions={<Actions/>}>
        <CustomBox display={'block'} p={3}>
          <PreviewContainer label={'App Banner'} source={'appImageUrl'} record={props.record}/>
          <PreviewContainer label={'DeskTop Web Banner'} source={'desktopWebImageUrl'} record={props.record}/>
          <PreviewContainer label={'Mobile Web Banner'} source={'mobileWebImageUrl'} record={props.record}/>
        </CustomBox>
      </Show>
  );
};

const myExporter = data => {
  const now = moment();
  _.forEach(data, (v, i) => {
    const sDate = moment(v.validStartAt);
    const eDate = moment(v.validEndAt);
    if (now.diff(sDate) >= 0 && now.diff(eDate) <= 0) {
      v.status = 'In progress';
    } else if (now.diff(sDate) > 0 && now.diff(eDate) > 0) {
      v.status = 'Done';
    } else if (now.diff(sDate) < 0 && now.diff(eDate) < 0) {
      v.status = 'Reserved';
    }
  })

  const csv = convertToCSV({
    data,
    fields: ['appImageUrl', 'validStartAt', 'validEndAt', 'position', 'status', 'deepLinkUrl', 'webLinkUrl', 'isDisable', 'sequenceNumber'],
  });
  downloadCSV(csv, 'banners');
};

const ListActions = (props) => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    ...rest
  } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
    total,
  } = useListContext();
  return (
          <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
              resource,
              showFilter,
              displayedFilters,
              filterValues,
              context: 'button',
            })}
            <CreateButton basePath={basePath} />
            <CustomExportButton
                    disabled={total === 0}
                    resource={resource}
                    sort={currentSort}
                    filterValues={filterValues}
                    maxResults={10}
                    exporter={myExporter}
            />
          </TopToolbar>
  );
};

export const BannerList = props => {
  return (
      <List {...props} title="Banner List"
            aside={<FilterSidebar/>}
            actions={<ListActions />}
            bulkActionButtons={false}
            pagination={<CommonPagination/>}
            filterDefaultValues={{isDisable: false}}
            sort={{field: 'createAt', order: 'DESC'}}>
        <Datagrid rowClick="edit" expand={<BannerImagesShow/>}>
          <BannerViewField source={'appImageUrl'} label={'Image'} textAlign={'center'} sortable={false}/>
          <DateField source="validStartAt" showTime label="Display start at"/>
          <DateField source="validEndAt" showTime label="Display end at"/>
          <TextField source="position" label={'Position'}/>
          <BannerStatusField label={'Status'} sortable={false}
                             textAlign={'center'}/>
          <CheckLinkField source="deepLinkUrl" label={'Deep Link'}
                          textAlign={'center'}/>
          <CheckLinkField source="webLinkUrl" label={'Web Link'}
                          textAlign={'center'}/>
          <BooleanField source="isDisable" label={'Is deleted'}/>
          <TextField source="sequenceNumber" label={'Seq.'}/>
        </Datagrid>
      </List>
  );
};

export default BannerList;
