import * as React from 'react';
import {
  Edit,
  SimpleForm,
  TextField,
  Toolbar,
  SaveButton,
  TextInput,
  DateField,
  BooleanInput,
  required,
  Labeled,
  DateTimeInput,
  SelectInput,
  ImageInput,
  ImageField,
  NumberInput,
} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';
import {CustomPageTitle} from '../Layouts';
import moment from 'moment-timezone';

const useCustomToolbarStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const DoNotDeleteToolbar = props => {
  const isChanged = !props.pristine;

  return (
      <Toolbar {...props} classes={useCustomToolbarStyles()}>
        <SaveButton disabled={!isChanged}/>
      </Toolbar>
  );
};

const CustomBannerUpdateInput = ({record, source, label, ...props}) => {
  const filePath = record && source ? record[source] : '';
  const ImageView = () => !filePath ? (<span/>) : (
      <div>
        <img src={`${filePath}`}
             style={{maxWidth: 350, maxHeight: 220}}/>
      </div>
  );

  return (
      <>
        <Labeled label={label || source}>
          <div style={{display: 'flex', flexWrap: 'wrap', maxWidth: 850}}>
            <ImageView/>
            <div style={{flexGrow: 1, marginLeft: 20}}>
              <ImageInput source={source} label={label} accept="image/*" placeholder={'Update Banner Image'}>
                <ImageField source={'src'} title="title"/>
              </ImageInput>
            </div>
          </div>
        </Labeled>
      </>
  );
};

const BannerDetailStatusField = ({record}) => {
  const sDate = moment(record.validStartAt);
  const eDate = moment(record.validEndAt);
  const now = moment();

  let status, color;
  if (now.diff(sDate) >= 0 && now.diff(eDate) <= 0) {
    status = 'In progress';
    color = '#27cc7d';
  } else if (now.diff(sDate) > 0 && now.diff(eDate) > 0) {
    status = 'Done';
    color = '#9a9a9a';
  } else if (now.diff(sDate) < 0 && now.diff(eDate) < 0) {
    status = 'Reserved';
    color = '#cc981d';
  } else {
    status = '';
    color = '#cc214a';
  }

  return (
      <Labeled label="Status">
        <span style={{color: color}}>{status}</span>
      </Labeled>
  )
};

export const BannerEdit = (props) => (
    <Edit {...props} title={<CustomPageTitle source={'title'} {...props} />}>
      <SimpleForm mutationMode="undoable" toolbar={<DoNotDeleteToolbar/>}>
        <TextField source="id"/>

        <CustomBannerUpdateInput source={'appImageUrl'}
                                 label={'App Image'}/>
        <CustomBannerUpdateInput source={'desktopWebImageUrl'}
                                 label={'Desktop Web Image'}/>
        <CustomBannerUpdateInput source={'mobileWebImageUrl'}
                                 label={'Mobile Web Image'}/>

        <DateTimeInput source="validStartAt" validate={[required()]} />
        <DateTimeInput source="validEndAt" validate={[required()]} />

        <SelectInput source="position" choices={[
          {
            text: 'HOME_MAIN',
            value: 'HOME_MAIN',
          },
        ]} optionText={'text'} optionValue={'value'} validate={[required()]}/>

        <BannerDetailStatusField />

        <TextInput source="deepLinkUrl" fullWidth/>
        <TextInput source="webLinkUrl" fullWidth/>

        <DateField source="createAt" showTime/>
        <BooleanInput label={'Is deleted'} source="isDisable"/>
        <NumberInput source="sequenceNumber" />

      </SimpleForm>
    </Edit>
);

export default BannerEdit;
