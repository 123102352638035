import lodashMemoize from "lodash/memoize";

var getMessage = function (message, messageArgs, value, values) {
  return typeof message === 'function'
    ? message({
      args: messageArgs,
      value: value,
      values: values,
    })
    : messageArgs
      ? {
        message: message,
        args: messageArgs,
      }
      : message;
};

var memoize = function (fn) {
  return lodashMemoize(fn, function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
      args[_i] = arguments[_i];
    }
    return JSON.stringify(args);
  });
};

export const trimmedMinLength = memoize(
  (min, message = 'ra.validation.minLength') => (value, values) => {
    const val = value.trim();
    return val.length < min
      ? getMessage(message, { min }, val, values)
      : undefined
  }
);
