import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import Downshift, { DownshiftProps } from 'downshift';
import get from 'lodash/get';
import classNames from 'classnames';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import {
  useInput,
  FieldTitle,
  useSuggestions,
  useTranslate,
} from 'ra-core';

import {InputHelperText} from 'ra-ui-materialui';
import CustomAutocompleteSuggestionList from './CustomAutocompleteSuggestionList';
import CustomAutocompleteSuggestionItem from './CustomAutocompleteSuggestionItem';
import CustomAutocompleteInputLoader from './CustomAutocompleteInputLoader';
import PropTypes from "prop-types";


const CustomAutocompleteInput = props => {
  const {
    allowEmpty,
    className,
    classes: classesOverride,
    clearAlwaysVisible,
    choices = [],
    disabled,
    emptyText,
    emptyValue,
    format,
    fullWidth,
    helperText,
    id: idOverride,
    input: inputOverride,
    isRequired: isRequiredOverride,
    label,
    limitChoicesToValue,
    loaded,
    loading,
    margin = 'dense',
    matchSuggestion,
    meta: metaOverride,
    onBlur,
    onChange,
    onFocus,
    options: {
      suggestionsContainerProps,
      labelProps,
      InputProps,
      ...options
    } = {
      suggestionsContainerProps: undefined,
      labelProps: undefined,
      InputProps: undefined,
    },
    optionText = 'name',
    inputText,
    optionValue = 'id',
    parse,
    resettable,
    resource,
    setFilter,
    shouldRenderSuggestions: shouldRenderSuggestionsOverride,
    source,
    suggestionLimit,
    translateChoice = true,
    validate,
    variant = 'filled',
    ...rest
  } = props;

  const classes = useStyles(props);

  let inputEl = useRef();
  let anchorEl = useRef();

  const translate = useTranslate();

  const {
    id,
    input,
    isRequired,
    meta: { touched, error, submitError },
  } = useInput({
    format,
    id: idOverride,
    input: inputOverride,
    meta: metaOverride,
    onBlur,
    onChange,
    onFocus,
    parse,
    resource,
    source,
    validate,
    ...rest,
  });

  const [filterValue, setFilterValue] = useState('');

  const getSuggestionFromValue = useCallback(
    value => choices.find(choice => get(choice, optionValue) === value),
    [choices, optionValue]
  );

  const selectedItem = useMemo(
    () => getSuggestionFromValue(input.value) || null,
    [input.value, getSuggestionFromValue]
  );

  const { getChoiceText, getChoiceValue, getSuggestions } = useSuggestions({
    allowEmpty,
    choices,
    emptyText,
    emptyValue,
    limitChoicesToValue,
    matchSuggestion,
    optionText,
    optionValue,
    selectedItem,
    suggestionLimit,
    translateChoice,
  });

  const handleFilterChange = useCallback(
    (eventOrValue) => {
      const value = eventOrValue.target ? eventOrValue.target.value : eventOrValue;

      if (setFilter) {
        setFilter(value);
      }
    },
    [setFilter]
  );

  useEffect(() => {
    handleFilterChange('');

    setFilterValue(
      typeof input.value === 'undefined' ||
      input.value === null ||
      selectedItem === null
        ? ''
        : inputText
        ? inputText(getChoiceText(selectedItem).props.record)
        : getChoiceText(selectedItem)
    );
  }, [
    input.value,
    handleFilterChange,
    selectedItem,
    getChoiceText,
    inputText,
  ]);

  const handleChange = useCallback(
    (item) => {
      if (getChoiceValue(item) == null && filterValue) {
        setFilterValue('');
      }

      input.onChange(getChoiceValue(item));
    },
    [filterValue, getChoiceValue, input]
  );

  const updateAnchorEl = () => {
    if (!inputEl.current) {
      return;
    }

    const inputPosition = inputEl.current.getBoundingClientRect();

    if (!anchorEl.current) {
      anchorEl.current = { getBoundingClientRect: () => inputPosition };
    } else {
      const anchorPosition = anchorEl.current.getBoundingClientRect();

      if (
        anchorPosition.x !== inputPosition.x ||
        anchorPosition.y !== inputPosition.y
      ) {
        anchorEl.current = {
          getBoundingClientRect: () => inputPosition,
        };
      }
    }
  };

  const storeInputRef = input => {
    inputEl.current = input;
    updateAnchorEl();
  };

  const handleBlur = useCallback(
    event => {
      handleFilterChange('');

      setFilterValue(
        input.value
          ? inputText
          ? inputText(getChoiceText(selectedItem).props.record)
          : getChoiceText(selectedItem)
          : ''
      );
      input.onBlur(event);
    },
    [getChoiceText, handleFilterChange, input, inputText, selectedItem]
  );

  const handleFocus = useCallback(
    openMenu => event => {
      openMenu(event);
      input.onFocus(event);
    },
    [input]
  );

  const shouldRenderSuggestions = val => {
    if (
      shouldRenderSuggestionsOverride !== undefined &&
      typeof shouldRenderSuggestionsOverride === 'function'
    ) {
      return shouldRenderSuggestionsOverride(val);
    }

    return true;
  };

  const { endAdornment, ...InputPropsWithoutEndAdornment } = InputProps || {};

  const handleClickClearButton = useCallback(
    openMenu => event => {
      event.stopPropagation();
      setFilterValue('');
      input.onChange('');
      openMenu(event);
      input.onFocus(event);
    },
    [input]
  );

  const getEndAdornment = openMenu => {
    if (!resettable) {
      if (endAdornment) {
        return endAdornment;
      }
      if (loading) {
        return <CustomAutocompleteInputLoader />;
      }
    } else if (!filterValue) {
      const label = translate('ra.action.clear_input_value');
      if (clearAlwaysVisible) {
        return (
          <InputAdornment position="end">
            <IconButton
              className={classes.clearButton}
              aria-label={label}
              title={label}
              disableRipple
              disabled={true}
            >
              <ClearIcon
                className={classNames(
                  classes.clearIcon,
                  classes.visibleClearIcon
                )}
              />
            </IconButton>
            {loading && <CustomAutocompleteInputLoader />}
          </InputAdornment>
        );
      } else {
        if (endAdornment) {
          return endAdornment;
        } else {
          return (
            <InputAdornment position="end">
              <span className={classes.clearButton}>&nbsp;</span>
              {loading && <CustomAutocompleteInputLoader />}
            </InputAdornment>
          );
        }
      }
    } else {
      const label = translate('ra.action.clear_input_value');
      return (
        <InputAdornment position="end">
          <IconButton
            className={classes.clearButton}
            aria-label={label}
            title={label}
            disableRipple
            onClick={handleClickClearButton(openMenu)}
            onMouseDown={handleMouseDownClearButton}
            disabled={disabled}
          >
            <ClearIcon
              className={classNames(classes.clearIcon, {
                [classes.visibleClearIcon]:
                clearAlwaysVisible || filterValue,
              })}
            />
          </IconButton>
          {loading && <CustomAutocompleteInputLoader />}
        </InputAdornment>
      );
    }
  };

  return (
    <Downshift
      inputValue={filterValue}
      onChange={handleChange}
      selectedItem={selectedItem}
      itemToString={item => getChoiceValue(item)}
      {...rest}
    >
      {({
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          isOpen,
          highlightedIndex,
          openMenu,
        }) => {
        const isMenuOpen =
          isOpen && shouldRenderSuggestions(filterValue);
        const {
          id: downshiftId,
          value,
          onBlur,
          onChange,
          onFocus,
          ref,
          size,
          color,
          ...inputProps
        } = getInputProps({
          onBlur: handleBlur,
          onFocus: handleFocus(openMenu),
          ...InputProps,
        });
        const suggestions = getSuggestions(filterValue);

        return (
          <div className={classes.container}>
            <TextField
              id={id}
              name={input.name}
              InputProps={{
                inputRef: storeInputRef,
                endAdornment: getEndAdornment(openMenu),
                onBlur,
                onChange: event => {
                  handleFilterChange(event);
                  setFilterValue(event.target.value);
                  onChange(event);
                },
                onFocus,
                ...InputPropsWithoutEndAdornment,
              }}
              error={!!(touched && (error || submitError))}
              label={
                <FieldTitle
                  label={label}
                  {...labelProps}
                  source={source}
                  resource={resource}
                  isRequired={
                    typeof isRequiredOverride !==
                    'undefined'
                      ? isRequiredOverride
                      : isRequired
                  }
                />
              }
              InputLabelProps={getLabelProps({
                htmlFor: id,
              })}
              helperText={
                <InputHelperText
                  touched={touched}
                  error={error || submitError}
                  helperText={helperText}
                />
              }
              disabled={disabled}
              variant={variant}
              margin={margin}
              fullWidth={fullWidth}
              value={filterValue}
              className={className}
              size={size}
              color={color}
              {...inputProps}
              {...options}
            />
            <CustomAutocompleteSuggestionList
              isOpen={isMenuOpen}
              menuProps={getMenuProps(
                {},
                { suppressRefError: true }
              )}
              inputEl={inputEl.current}
              suggestionsContainerProps={
                suggestionsContainerProps
              }
              className={classes.suggestionsContainer}
            >
              {suggestions.map((suggestion, index) => (
                <CustomAutocompleteSuggestionItem
                  key={getChoiceValue(suggestion)}
                  suggestion={suggestion}
                  index={index}
                  highlightedIndex={highlightedIndex}
                  isSelected={
                    input.value ===
                    getChoiceValue(suggestion)
                  }
                  filterValue={filterValue}
                  getSuggestionText={getChoiceText}
                  {...getItemProps({
                    item: suggestion,
                  })}
                />
              ))}
            </CustomAutocompleteSuggestionList>
          </div>
        );
      }}
    </Downshift>
  );
};

const handleMouseDownClearButton = event => {
  event.preventDefault();
};

const useStyles = makeStyles(
  {
    container: {
      flexGrow: 1,
      position: 'relative',
    },
    clearIcon: {
      height: 16,
      width: 0,
    },
    visibleClearIcon: {
      width: 16,
    },
    clearButton: {
      height: 24,
      width: 24,
      padding: 0,
    },
    selectAdornment: {
      position: 'absolute',
      right: 24,
    },
    inputAdornedEnd: {
      paddingRight: 0,
    },
    suggestionsContainer: {},
  },
  { name: 'RaAutocompleteInput' }
);

CustomAutocompleteInput.propTypes = {
  clearAlwaysVisible: PropTypes.bool,
  resettable: PropTypes.bool,
  loaded: PropTypes.bool,
  loading: PropTypes.bool,
};

export default CustomAutocompleteInput;
