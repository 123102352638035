import * as React from "react";
import {
	Edit,
	Labeled,
	TabbedForm,
	FormTab,
	TextField,
	DateField,
	BooleanField,
	FormWithRedirect,
	Toolbar,
	SaveButton,
	ImageField,
	ReferenceField,
	ReferenceManyField,
	Datagrid,
	Pagination,
	BooleanInput,
	
} from "react-admin";
import { Grid, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CustomPageTitle } from "../Layouts";

const BookInfos = (props) => (
	<FormWithRedirect
		{...props}
		render={(formProps) => (
			<Box display={"flex"}>
				<Box mr={4}>
					<ImageField source="coverImageUrl" record={formProps.record} />
				</Box>
				<Box>
					<div>
						<Labeled label="Title">
							<TextField source="title" record={formProps.record} />
						</Labeled>
					</div>
					<div>
						<Labeled label="Genre">
							<TextField
								source="genreName"
								record={formProps.record}
								style={{ whiteSpace: "pre-wrap" }}
								fullWidth
							/>
						</Labeled>
					</div>
				</Box>
			</Box>
		)}
	/>
);

const ExtraNovelInfo = (props) => (
	<FormWithRedirect
		{...props}
		render={(formProps) => (
			<div>
				<Grid container spacing={4}>
					<Grid item>
						<Labeled label="Created at">
							<DateField source="createAt" showTime record={formProps.record} />
						</Labeled>
					</Grid>
					<Grid item>
						<Labeled label="Last chapter updated at">
							<DateField
								source="lastChapterUpdateAt"
								showTime
								record={formProps.record}
							/>
						</Labeled>
					</Grid>
				</Grid>
				<Grid container spacing={4}>
					<Grid item>
						<Labeled label="Target">
							<TextField
								source="targetReaderSegmentCode"
								record={formProps.record}
							/>
						</Labeled>
					</Grid>
					<Grid item>
						<Labeled label="Status">
							<TextField
								source="serializingStatusCode"
								record={formProps.record}
							/>
						</Labeled>
					</Grid>
				</Grid>
			</div>
		)}
	/>
);

const CustomGrid = ({ basePath, ...props }) => {
	return <Grid spacing={4} {...props} />;
};

const useCustomToolbarStyles = makeStyles({
	toolbar: {
		display: "flex",
		justifyContent: "space-between",
	},
});

const DoNotDeleteToolbar = (props) => {
	const isChanged = !props.pristine;

	return (
		<Toolbar {...props} classes={useCustomToolbarStyles()}>
			<SaveButton disabled={!isChanged} />
		</Toolbar>
	);
};

export const NovelEdit = (props) => {
	return (
		<Edit
			{...props}
			mutationMode="optimistic"
			title={<CustomPageTitle source={"title"} {...props} />}
		>
			<TabbedForm undoable={false} toolbar={<DoNotDeleteToolbar />}>
				<FormTab label="Info">
					<TextField source="id" />
					<BookInfos />
					<TextField source="summary" style={{ whiteSpace: "pre-wrap" }} />
					<ReferenceField
						label={"Author"}
						reference="users"
						source="authorUserPublicId"
					>
						<TextField source="displayName" />
					</ReferenceField>
					<DateField label="Contract finished at" source="contractFinishes" style={{ whiteSpace: "pre-wrap" }}/>
					<ExtraNovelInfo />
					<CustomGrid container>
						<Grid item>
							<Labeled label={"Published"}>
								<BooleanInput source="isPublic" label={""} />
							</Labeled>
						</Grid>
						<Grid item>
							<Labeled label={"Is deleted"}>
								<BooleanInput source="isDisable" label={""} />
							</Labeled>
						</Grid>
					</CustomGrid>
					<Grid container>
						<Grid item>
							<Labeled label={"Joara Books"}>
								<BooleanInput source="isJoaraBooks" label={""} />
							</Labeled>
						</Grid>
						<Grid item>
							<Labeled label={"SUN"}>
								<BooleanInput source="isSun" label={""} />
							</Labeled>
						</Grid>
						<Grid item>
							<Labeled label={"MON"}>
								<BooleanInput source="isMon" label={""} />
							</Labeled>
						</Grid>
						<Grid item>
							<Labeled label={"TUE"}>
								<BooleanInput source="isTue" label={""} />
							</Labeled>
						</Grid>
						<Grid item>
							<Labeled label={"WED"}>
								<BooleanInput source="isWed" label={""} />
							</Labeled>
						</Grid>
						<Grid item>
							<Labeled label={"THU"}>
								<BooleanInput source="isThu" label={""} />
							</Labeled>
						</Grid>
						<Grid item>
							<Labeled label={"FRI"}>
								<BooleanInput source="isFri" label={""} />
							</Labeled>
						</Grid>
						<Grid item>
							<Labeled label={"SAT"}>
								<BooleanInput source="isSat" label={""} />
							</Labeled>
						</Grid>
					</Grid>
				</FormTab>
				<FormTab label="Episodes" path="episodes">
					<ReferenceManyField
						label=""
						reference="chapters"
						target={`novels/${props.id}/chapters`}
						fullWidth
						perPage={10}
						pagination={<Pagination />}
						sort={{ field: "createAt", order: "ASC" }}
					>
						<Datagrid rowClick="show" style={{ width: "100%" }}>
							<TextField source="title" />
							<DateField source="createAt" showTime={false} />
							<DateField source="updateAt" showTime={false} />
							<BooleanField label={"Is deleted"} source="isDisable" />
						</Datagrid>
					</ReferenceManyField>
				</FormTab>
			</TabbedForm>
		</Edit>
	);
};

export default NovelEdit;
