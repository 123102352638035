import { defaultTheme } from "react-admin";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import createPalette from "@material-ui/core/styles/createPalette";
import merge from "lodash/merge";

const palette = createPalette(
    merge({}, defaultTheme.palette, {
      primary: {
        main: "#1586ff",
      },
      secondary: {
        main: "#1a1a1a",
      },
    })
);

const typography = {
  fontFamilySecondary: 'sans-serif',
  fontFamily: 'Noto Sans KR',
  fontSize: 15,
  fontStyle: "normal",
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 700,
  fontWeightBold: 900,
  color: palette.text.primary,
};

const typographyBase = {
  fontFamily: typography.fontFamily,
  fontSize: typography.fontSize,
  fontStyle: typography.fontStyle,
  color: typography.color,
  fontWeight: typography.fontWeightRegular,
};

const typographyHeader = {
  ...typographyBase,
  fontWeight: typography.fontWeightBold,
  fontFamily: typography.fontFamilySecondary,
};

const typographyBody = {
  ...typographyBase,
  fontWeight: typography.fontWeightRegular,
  fontFamily: typography.fontFamily,
};

const rawTheme = {
  palette,
  typography: {
    ...typographyBase,
    h1: {
      ...typographyHeader,
      textTransform: "uppercase",
      fontSize: "4rem",
    },
    root: {
      ...typographyBody,
    },
    body1: {
      ...typographyBody,
      fontSize: "1rem",
    },
    body2: {
      ...typographyBody,
    },
    button: {
      ...typographyBody,
    },
    table: {
      ...typographyBody,
      cell: {
        ...typographyBody,
      },
    },
    link: {
      root: {
        ...typographyBody,
      },
      ...typographyBody,
    },
  },
};

export const theme = createMuiTheme(
    merge({}, defaultTheme, rawTheme)
);
