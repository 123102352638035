const fbProject =
  process.env.REACT_APP_ENV === "localhost"
    ? process.env.REACT_APP_LOCAL_FIREBASE_PROJECT_ID
    : process.env.REACT_APP_ENV === "development"
    ? process.env.REACT_APP_DEV_FIREBASE_PROJECT_ID
    : process.env.REACT_APP_PRD_FIREBASE_PROJECT_ID || "default";

export const config = {
  default: {
    // joara-global-dev
    apiKey: "AIzaSyBb7k64VEIxpiSM40luR459DUfuqO4SZbU",
    authDomain: "joara-global-dev.firebaseapp.com",
    databaseURL: "https://joara-global-dev.firebaseio.com",
    projectId: "joara-global-dev",
    storageBucket: "joara-global-dev",
    messagingSenderId: "339295357850",
    appId: "1:339295357850:web:a32fe6fe9bd1320070c823",
    measurementId: "G-R4MD9Y1MJQ",
  },
  "joara-global-dev": {
    apiKey: "AIzaSyBb7k64VEIxpiSM40luR459DUfuqO4SZbU",
    authDomain: "joara-global-dev.firebaseapp.com",
    databaseURL: "https://joara-global-dev.firebaseio.com",
    projectId: "joara-global-dev",
    storageBucket: "joara-global-dev",
    messagingSenderId: "339295357850",
    appId: "1:339295357850:web:a32fe6fe9bd1320070c823",
    measurementId: "G-R4MD9Y1MJQ",
  },
  "joara-global-dev3": {
    apiKey: "AIzaSyCot4DRpR5nw2o6q_rvTdXtdu46E-IOYmw",
    authDomain: "joara-global-dev3.firebaseapp.com",
    projectId: "joara-global-dev3",
    storageBucket: "joara-global-dev3.appspot.com",
    messagingSenderId: "493119745023",
    appId: "1:493119745023:web:b7cd33df7bd573b01d55ca",
    measurementId: "G-XF4FCLEMRM",
  },
  "ajoara-dev": {
    apiKey: "AIzaSyBUNk0yo45svMnSvfu-ZL_ZF2evip4WXCc",
    authDomain: "ajoara-dev.firebaseapp.com",
    projectId: "ajoara-dev",
    storageBucket: "ajoara-dev.appspot.com",
    messagingSenderId: "1065087637399",
    appId: "1:1065087637399:web:58db4a40e17992d063d6d2",
    measurementId: "G-V1HCLXF465",
  },
  "ajoara-prd": {
    apiKey: "AIzaSyAXfRdQHp3odg-aceBuEBrlm2BSx34Gq7I",
    authDomain: "ajoara-prd.firebaseapp.com",
    projectId: "ajoara-prd",
    storageBucket: "ajoara-prd.appspot.com",
    messagingSenderId: "205463909704",
    appId: "1:205463909704:web:6288b0558681fa71cfaf7d",
    measurementId: "G-8P1G78D223",
  },
};
export default config[fbProject];
