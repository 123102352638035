import * as React from 'react';
import { FilterList, FilterListItem, Filter, SearchInput, BooleanInput, SelectInput } from 'react-admin';
import { Adjust as StatusIcon } from '@material-ui/icons';

export const SearchFilter = props => (
    <Filter {...props}>
      <SearchInput source="q" alwaysOn/>
    </Filter>
);

export const StatusFilter = () => {
  return (
      <FilterList label={'Status'} icon={<StatusIcon/>}>
        <FilterListItem label={'(not set)'} value={{
          'applyStartAt_gte': null,
          'applyStartAt_lte': null,
          'applyEndAt_gte': null,
          'applyEndAt_lte': null,
        }} />
        <FilterListItem label={'Reserved'} value={{
          'applyStartAt_gte': true,
          'applyStartAt_lte': undefined,
          'applyEndAt_gte': true,
          'applyEndAt_lte': undefined,
        }} />
        <FilterListItem label={'In Progress'} value={{
          'applyStartAt_gte': undefined,
          'applyStartAt_lte': true,
          'applyEndAt_gte': true,
          'applyEndAt_lte': undefined,
        }} />
        <FilterListItem label={'Done'} value={{
          'applyStartAt_gte': undefined,
          'applyStartAt_lte': true,
          'applyEndAt_gte': undefined,
          'applyEndAt_lte': true,
        }} />
      </FilterList>
  )
}

    export const SelectFilter = (props) => {
  return (
      <FilterList {...props}>
        <Filter>
          <SelectInput {...props} optionValue={'value'} optionText={'text'} emptyText={'(not set)'} emptyValue={null} label={''} alwaysOn/>
        </Filter>
      </FilterList>
  )
};

export const BooleanFilter = (props) => {
  return (
      <Filter {...props}>
        <BooleanInput alwaysOn {...props} />
      </Filter>
  )
};

