import axios from '../plugins/axios';
import ProcessAuth from '../utils/ProcessAuth';
import _ from 'lodash';
import {
  CREATE,
  DELETE,
  DELETE_MANY,
  fetchUtils,
  GET_LIST,
  GET_MANY,
  GET_MANY_REFERENCE,
  GET_ONE,
  UPDATE,
  UPDATE_MANY,
} from 'react-admin';
import moment from 'moment-timezone';

const authProcessor = new ProcessAuth(process.env.REACT_APP_USER_STATE_SCOPE);

function jsonToQueryString(json, isFirst) {
  const symbol = isFirst ? '?' : '&';
  return symbol +
      Object.keys(json).map(function(key) {
        return encodeURIComponent(key) + '=' +
            encodeURIComponent(json[key]);
      }).join('&');
}

/**
 * Maps react-admin queries to a REST API implemented using Java Spring Boot and Swagger
 *
 * @example
 * GET_LIST     => GET http://my.api.url/posts?page=0&pageSize=10
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?id=1234&id=5678
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts
 * DELETE       => DELETE http://my.api.url/posts/123
 */
export default (apiUrl, httpClient = fetchUtils.fetchJson) => {
  /**
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} { url, options } The HTTP request parameters
   */
  const convertDataRequestToHTTP = (type, resource, params) => {
    let url = '';
    const options = {};
    
    switch (type) {
      case GET_LIST: {
        const {page, perPage} = params.pagination;
        const {field, order} = params.sort;
        
        if (resource === 'users') {
          if (params.filter.createAt_gte) {
            params.filter.createAt_gte = moment(params.filter.createAt_gte).
                toISOString();
          }
          if (params.filter.createAt_lte) {
            params.filter.createAt_lte = moment(params.filter.createAt_lte).
                toISOString();
          }
        } else if (resource === 'deleted-accounts') {
          resource = 'withdrawal-users';
          if (params.filter.deleteAt_gte) {
            params.filter.deleteAt_gte = moment(params.filter.deleteAt_gte).
                toISOString();
          }
          if (params.filter.deleteAt_lte) {
            params.filter.deleteAt_lte = moment(params.filter.deleteAt_lte).
                toISOString();
          }
        } else if (resource === 'novels') {
          if (params.filter.createAt_gte) {
            params.filter.createAt_gte = moment(params.filter.createAt_gte).
                toISOString();
          }
          if (params.filter.createAt_lte) {
            params.filter.createAt_lte = moment(params.filter.createAt_lte).
                toISOString();
          }
          if (params.filter.lastChapterUpdateAt_gte) {
            params.filter.lastChapterUpdateAt_gte = moment(
                params.filter.lastChapterUpdateAt_gte).toISOString();
          }
          if (params.filter.lastChapterUpdateAt_lte) {
            params.filter.lastChapterUpdateAt_lte = moment(
                params.filter.lastChapterUpdateAt_lte).toISOString();
          }
        } else if (resource === 'banners') {
          if (params.filter.validStartAt_gte) {
            params.filter.validStartAt_gte = moment().toISOString();
          }
          if (params.filter.validStartAt_lte) {
            params.filter.validStartAt_lte = moment().toISOString();
          }
          if (params.filter.validEndAt_gte) {
            params.filter.validEndAt_gte = moment().toISOString();
          }
          if (params.filter.validEndAt_lte) {
            params.filter.validEndAt_lte = moment().toISOString();
          }
        } else if (resource === 'contests') {
          if (params.filter.applyStartAt_gte) {
            params.filter.applyStartAt_gte = moment().toISOString();
          }
          if (params.filter.applyStartAt_lte) {
            params.filter.applyStartAt_lte = moment().toISOString();
          }
          if (params.filter.applyEndAt_gte) {
            params.filter.applyEndAt_gte = moment().toISOString();
          }
          if (params.filter.applyEndAt_lte) {
            params.filter.applyEndAt_lte = moment().toISOString();
          }
        }
        
        url = `${apiUrl}/${resource}?page=${page}&size=${perPage}&sort=${field}&order=${order}${jsonToQueryString(
            params.filter, false)}`;
        break;
      }
      case GET_ONE:
        if (params.id === undefined) {
          break;
        }
        if (resource === 'notices' || resource === 'helps' || resource ===
            'communities') {
          resource = 'posts';
        }
        if (resource === 'deleted-accounts') {
          resource = 'users';
        }
        url = `${apiUrl}/${resource}/${params.id}`;
        break;
      case GET_MANY: {
        const query = {
          filter: JSON.stringify({id: params.ids}),
        };
        let idStr = '';
        params.ids.map(id => idStr += `publicId=${id}&`);
        url = `${apiUrl}/${resource}?${idStr.substring(0, idStr.length - 1)}`;
        break;
      }
      case GET_MANY_REFERENCE: {
        const {page, perPage} = params.pagination;
        const {field, order} = params.sort;
        
        if (params.target.indexOf('/') !== -1) {
          url = `${apiUrl}/${params.target}?page=${page}&size=${perPage}&sort=${field}&order=${order}`;
        } else {
          url = `${apiUrl}/${resource}?page=${page}&size=${perPage}&sort=${field}&order=${order}&id=${params.id}${jsonToQueryString(
              params.filter, false)}`;
        }
        break;
      }
      case UPDATE:
        if (resource === 'notices' || resource === 'helps' || resource ===
            'communities' || resource === 'book-reviews') {
          resource = 'posts';
        }
        url = `${apiUrl}/${resource}/${params.id}`;
        params.data.disableCode = 'DATA_STAFF_ACTION';
        options.method = 'PATCH';
        if (resource.indexOf('banners') !== -1) {
          const data = _.pick(params.data, [
            'validStartAt',
            'validEndAt',
            'appImageUrl',
            'desktopWebImageUrl',
            'mobileWebImageUrl',
            'position',
            'deepLinkUrl',
            'webLinkUrl',
            'sequenceNumber',
            'isDisable']);
          var formData = new FormData();
          for (let key in data) {
            if (key === 'appImageUrl' || key === 'desktopWebImageUrl' || key ===
                'mobileWebImageUrl') {
              if (data[key].hasOwnProperty('rawFile')) {
                formData.append(key, data[key].rawFile);
              }
            } else if (key === 'validStartAt' || key === 'validEndAt') {
              if (params.data[key] instanceof Date) {
                formData.append(key, params.data[key].getTime());
              } else {
                formData.append(key, new Date(data[key]).getTime());
              }
            } else {
              formData.append(key, data[key] === null ? '' : data[key]);
            }
          }
          options.data = formData;
        } else {
          options.data = params.data;
        }
        break;
      case CREATE:
        const user = authProcessor.getUser();
        params.data.writerUserPublicId = user.userPublicId;
        url = `${apiUrl}/${resource}`;
        options.method = 'POST';
        if (resource.indexOf('banners') !== -1) {
          var formData = new FormData();
          for (let key in params.data) {
            console.log(`${key}:`, params.data[key]);
            if (params.data[key].rawFile !== undefined) {
              formData.append(key, params.data[key].rawFile);
            } else {
              if (params.data[key] instanceof Date) {
                formData.append(key, params.data[key].getTime());
              } else {
                formData.append(key, params.data[key]);
              }
            }
          }
          options.data = formData;
        } else {
          options.data = params.data;
        }
        break;
      case DELETE:
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'DELETE';
        if (resource == 'users') {
          url = `${apiUrl}/${resource}/${params.id + '?reason=' +
          params.data.reason}`;
        }
        break;
      default:
        throw new Error(`Unsupported fetch action type ${type}`);
    }
    return {url, options};
  };
  
  /**
   * @param {Object} response HTTP response from fetch()
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} Data response
   */
  const convertHTTPResponse = (response, type, resource, params) => {
    const {headers, data} = response;
    switch (type) {
      case GET_LIST:
      case GET_MANY_REFERENCE:
      case GET_MANY:
        if (!data.hasOwnProperty('total')) {
          throw new Error(
              'The numberOfElements property must be must be present in the Json response',
          );
        }
        return {
          data: data.data,
          total: parseInt(data.total, 10),
        };
      case CREATE:
        return {data: {...params.data, id: data.id}};
      default:
        return {data: data};
    }
  };
  
  /**
   * @param {string} type Request type, e.g GET_LIST
   * @param {string} resource Resource name, e.g. "posts"
   * @param {Object} payload Request parameters. Depends on the request type
   * @returns {Promise} the Promise for a data response
   */
  return (type, resource, params) => {
    if (type === UPDATE_MANY) {
      return Promise.all(
          params.ids.map(id =>
              httpClient(`${apiUrl}/${resource}/${id}`, {
                method: 'PUT',
                body: JSON.stringify(params.data),
              }),
          ),
      ).then(responses => ({
        data: responses.map(response => response.data),
      }));
    }
    
    if (type === DELETE_MANY) {
      return Promise.all(
          params.ids.map(id =>
              httpClient(`${apiUrl}/${resource}/${id}`, {
                method: 'DELETE',
              }),
          ),
      ).then(responses => ({
        data: responses.map(response => response.data),
      }));
    }
    
    const {url, options} = convertDataRequestToHTTP(type, resource, params);
    
    if (url.indexOf('autoComplete') !== -1 && url.indexOf('q=') === -1) {
      return Promise.resolve({
        data: [],
        total: 0,
      });
    }
    
    return axios(url, options).then(response =>
        convertHTTPResponse(response, type, resource, params),
    ).catch((e) => {
      throw e.response.data.error;
    });
  };
};
