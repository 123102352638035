import * as React from 'react';
import {
  DateInput,
  Filter,
  FilterList,
  FilterListItem,
  SearchInput,
} from 'react-admin';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import {Notifications as PushAllowedIcon} from '@material-ui/icons';
import {Box} from '@material-ui/core';

export const SearchFilter = props => (
    <Filter {...props}>
      <SearchInput source="q" placeholder="Search" alwaysOn/>
    </Filter>
);

export const RoleFilter = () => (
    <FilterList label="Role" icon={<AccessTimeIcon/>}>
      <FilterListItem label={'(not set)'} value={{
        roleCode: null,
      }}/>
      <FilterListItem label={'Admin'} value={{
        roleCode: 'ADMIN',
      }}/>
      <FilterListItem label={'User'} value={{
        roleCode: 'USER',
      }}/>
    </FilterList>
);

export const PushAllowedFilter = () => (
    <FilterList label="Push Allowed" icon={<PushAllowedIcon/>}>
      <FilterListItem label={'(not set)'} value={{
        isPushAllowed: null,
      }}/>
      <FilterListItem label={'Yes'} value={{
        isPushAllowed: true,
      }}/>
      <FilterListItem label={'No'} value={{
        isPushAllowed: false,
      }}/>
    </FilterList>
);

export const IsAdultFilter = () => (
    <FilterList label="Is Adult" icon={<PushAllowedIcon/>}>
      <FilterListItem label={'(not set)'} value={{
        isMature: null,
      }}/>
      <FilterListItem label={'Yes'} value={{
        isMature: true,
      }}/>
      <FilterListItem label={'No'} value={{
        isMature: false,
      }}/>
    </FilterList>
);

export const CreateAtFilter = () => (
    <FilterList label={'create at'} icon={<AccessTimeIcon/>}>
      <Box mt={1}>
        <Filter>
          <DateInput label={'from'} source={'createAt_gte'} alwaysOn/>
          <DateInput label={'to'} source={'createAt_lte'} alwaysOn/>
        </Filter>
      </Box>
    </FilterList>
);
