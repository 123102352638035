import * as React from "react";
import {
	FilterList,
	Filter,
	SearchInput,
	BooleanInput,
	SelectInput,
	ReferenceInput,
	AutocompleteInput,
} from "react-admin";
import CustomAutocompleteInput from "../custom/CustomAutocompleteInput";

export const SearchFilter = (props) => (
	<Filter {...props}>
		<SearchInput source="q" alwaysOn />
	</Filter>
);

export const ReferenceAutoComplete = ({
	label,
	source,
	reference,
	referenceSource,
	optionText,
	...props
}) => {
	const condition = (val) => val.trim().length > 0;

	return (
		<Filter {...props}>
			<ReferenceInput
				label={label || ""}
				source={source || "id"}
				reference={reference}
				perPage={10}
				alwaysOn
				sort={{ field: "autoComplete", order: "DESC" }}
			>
				<CustomAutocompleteInput
					shouldRenderSuggestions={condition}
					loaded={true}
					loading={false}
					optionText={optionText || "id"}
					resettable
					alwaysOn
				/>
			</ReferenceInput>
		</Filter>
	);
};

export const SelectFilter = (props) => {
	return (
		<FilterList {...props}>
			<Filter>
				<SelectInput
					{...props}
					optionValue={"value"}
					optionText={"text"}
					emptyText={"(not set)"}
					emptyValue={null}
					label={""}
					alwaysOn
				/>
			</Filter>
		</FilterList>
	);
};

export const BooleanFilter = (props) => {
	return (
		<Filter {...props}>
			<BooleanInput alwaysOn {...props} />
		</Filter>
	);
};
