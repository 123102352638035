import AccountService from "../services/account.service";
import firebase from '../plugins/firebase';
import ProcessAuth from '../utils/ProcessAuth';
import axios from '../http-common';

firebase.auth().setPersistence(firebase.auth.Auth.Persistence[process.env.REACT_APP_USER_STATE_SCOPE.toUpperCase()]);
const authProcessor = new ProcessAuth(process.env.REACT_APP_USER_STATE_SCOPE);


const authProvider = {
  login: async ({ username, password }) =>  {
    try {
      await firebase.auth().signInWithEmailAndPassword(username, password);

      const fbUser = firebase.auth().currentUser;

      const token = await fbUser.getIdToken().then(token => {
        authProcessor.setToken(token);
        axios.defaults.headers['Authorization'] = `Bearer ${token}`;

        return token;
      });

      const user = await AccountService.signIn(fbUser.email, password).catch((e) => {
        throw e.response.data.error;
      });

      if (!user.profileImageUrl) user.profileImageUrl = fbUser.photoURL || null;

      authProcessor.setUser(user.data);

      return user;
    } catch(e) {
      await firebase.auth().signOut();
      throw e;
    } finally {
    }
  },
  checkError: (error) => {
    let status = error.status;
    if (!status && error.response) status = error.response.status;

    if (error.code === '401' || error.code === '403') {
      return Promise.reject({ redirectTo: '#/login' });
    }

    return Promise.resolve();
  },
  checkAuth: async () => {
    const storedUser = authProcessor.getUser();

    return new Promise((resolve, reject) => {
      const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
        unsubscribe();
        if (storedUser && user) {
          user.getIdToken().then((idToken) => {
            axios.defaults.headers.common['Authorization'] = `Bearer ${idToken}`;
            resolve(idToken);
          }, (error) => {
            reject(error);
          });
        } else if (user) {
        } else {
          reject({ message: false });
        }
      });
    });
  },
  logout: async () => {
    const user = authProcessor.getUser();
    if (user) {
      const publicUserId = user.userPublicId;
      await AccountService.signOut(publicUserId);
      authProcessor.clearAuth();
      await firebase.auth().signOut();
    }
  },
  getIdentity: () => {
    return new Promise((resolve, reject) => {
      const storedUser = authProcessor.getUser();
      if (authProcessor.getUser()) resolve({
        id: storedUser.email,
        fullName: storedUser.displayName,
        avatar: storedUser.profileImageUrl,
      });

      const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
        unsubscribe();
        if (user != null) {
          user.getIdToken().then(async (idToken) => {
            if (storedUser) {
              const identity = {
                id: storedUser.email || user.email,
                fullName: storedUser.displayName || user.displayName,
                avatar: storedUser.profileImageUrl || user.photoURL,
              };
              authProcessor.setToken(idToken);
              axios.defaults.headers.common['Authorization'] = `Bearer ${idToken}`;
              resolve(identity);
            } else {
              reject({ message: false });
            }
          }, (error) => {
            reject(error);
          });
        }
      });
    });
  },
  getPermissions: (params) => {
    return new Promise((resolve, reject) => {
      const storedUser = authProcessor.getUser();
      if (storedUser) {
        resolve('admin');
      } else {
        reject({ message: false });
      }
    });
  },
};

export default authProvider;
