import * as React from 'react';
import { Layout } from 'react-admin';
import AppBar from './AppBar';
import { theme } from './theme';

export default (props) => (
    <Layout
        {...props}
        appBar={AppBar}
        theme={theme}
    />
);
