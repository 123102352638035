import * as React from 'react';
import {
  Edit,
  SimpleForm,
  TextField,
  Toolbar,
  SaveButton,
  ReferenceField,
  DateField,
  BooleanInput,
  required,
} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';
import {CustomPageTitle} from '../Layouts';
import {trimmedMinLength} from "../custom/customValidate";

const useCustomToolbarStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const DoNotDeleteToolbar = props => {
  const isChanged = !props.pristine;

  return (
      <Toolbar {...props} classes={useCustomToolbarStyles()}>
        <SaveButton disabled={!isChanged}/>
      </Toolbar>
  );
};


export const NoticeCommentEdit = (props) => (
    <Edit {...props} mutationMode="optimistic" title={<CustomPageTitle source={'content'} {...props}/>}>
      <SimpleForm toolbar={<DoNotDeleteToolbar/>}>
        <TextField source="id" />
        <ReferenceField label="Notice Id" reference="notices" source="postPublicId" fullWidth>
          <TextField source="id"/>
        </ReferenceField>
        <TextField source="comment" fullWidth style={{whiteSpace: 'pre-wrap'}} validate={[required(), trimmedMinLength(1)]}/>
        <DateField source="createAt" showTime/>
        <BooleanInput label={'Is deleted'} source="isDisable" />
      </SimpleForm>
    </Edit>
);

export default NoticeCommentEdit;
