import {Button} from '@material-ui/core';
import {ArrowBack as ArrowBackIcon} from '@material-ui/icons';
import * as React from 'react';
import {Link} from 'react-router-dom';

const LinkToResourceBaseButton = (props) => {
  return (
      <Button color={'inherit'} component={Link} to={{
        pathname: props.basePath,
      }}>
        <ArrowBackIcon/>
      </Button>
  );
};

export default LinkToResourceBaseButton;
