import React from 'react';
import './App.css';
import {theme} from './Layouts/theme';
import {Layout, Login} from './Layouts';

import authProvider from './services/authProvider';
import englishMessages from 'ra-language-english';

import {Admin, Resource, ShowGuesser} from 'react-admin';
import springBootServerProvider from './services/spring-boot-rest-provider';

import {
  AssignmentInd as ApplicantIcon,
  Book as ChapterIcon,
  CollectionsBookmark as NovelIcon,
  Comment as CommentIcon,
  EmojiEvents as ContestIcon,
  Forum as CommunityIcon,
  Help as HelpIcon,
  MenuBookOutlined as BookReviewIcon,
  NotificationImportant as NoticeIcon,
  Person as UserIcon,
  ViewCarousel as BannerIcon,
} from '@material-ui/icons';

import {Edit as UserEdit, List as UserList} from './users';

import {Edit as NovelEdit, List as NovelList} from './novels';
import {
  Create as NoticeCreate,
  Edit as NoticeEdit,
  List as NoticeList,
} from './notices';
import {
  Create as NoticeCommentCreate,
  Edit as NoticeCommentEdit,
  List as NoticeCommentList,
} from './noticeComments';
import {Edit as HelpEdit, List as HelpList} from './help';
import {
  Create as HelpCommentCreate,
  Edit as HelpCommentEdit,
  List as HelpCommentList,
} from './helpComments';
import {
  Create as BannerCreate,
  Edit as BannerEdit,
  List as BannerList,
} from './banners';
import {
  Create as ContestCreate,
  Edit as ContestEdit,
  List as ContestList,
} from './contests';
import {
  Edit as ContestApplicantEdit,
  List as ContestApplicantList,
} from './contestApplicants';

import {JoaraBooksList} from './joaraBooks/List.js';

import {Edit as CommunityEdit, List as CommunityList} from './community';

import {Edit as BookReviewEdit, List as BookReviewList} from './bookReviews';

import {DeleteAccountList} from './deleteAccount/List.js';

import polyglotI18nProvider from 'ra-i18n-polyglot';
import Constants from './constants/Constants';

const adminResource = [
  <Resource name={'users'} list={UserList} edit={UserEdit} icon={UserIcon}/>,
  <Resource name={'deleted-accounts'} list={DeleteAccountList} edit={UserEdit}
            icon={UserIcon}/>,
  <Resource
      name={'novels'}
      list={NovelList}
      edit={NovelEdit}
      icon={NovelIcon}
  />,
  <Resource name={'chapters'} show={ShowGuesser} icon={ChapterIcon}/>,
  <Resource
      name={'notices'}
      create={NoticeCreate}
      list={NoticeList}
      edit={NoticeEdit}
      icon={NoticeIcon}
  />,
  <Resource
      name={'notice-comments'}
      create={NoticeCommentCreate}
      list={NoticeCommentList}
      edit={NoticeCommentEdit}
      icon={CommentIcon}
  />,
  <Resource name={'helps'} list={HelpList} edit={HelpEdit} icon={HelpIcon}/>,
  <Resource
      name={'help-comments'}
      create={HelpCommentCreate}
      list={HelpCommentList}
      edit={HelpCommentEdit}
      icon={CommentIcon}
  />,
  <Resource
      name={'banners'}
      create={BannerCreate}
      list={BannerList}
      edit={BannerEdit}
      icon={BannerIcon}
  />,
  <Resource
      name={'contests'}
      create={ContestCreate}
      list={ContestList}
      edit={ContestEdit}
      icon={ContestIcon}
  />,
  <Resource
      name={'contest-applicants'}
      list={ContestApplicantList}
      edit={ContestApplicantEdit}
      icon={ApplicantIcon}
  />,
  <Resource
      name={'joara-books'}
      list={JoaraBooksList}
      icon={NovelIcon}
  />,
  <Resource
      name={'communities'}
      list={CommunityList}
      edit={CommunityEdit}
      icon={CommunityIcon}
  />,
  <Resource
      name={'book-reviews'}
      list={BookReviewList}
      edit={BookReviewEdit}
      icon={BookReviewIcon}
  />,
];

const englishCustomMessages = englishMessages;

const originalLog = console.error;
console.error = function log(...args) {
  if (
      args.length > 0 &&
      typeof args[0] === 'string' &&
      /^Warning: Missing translation/.test(args[0])
  ) {
    return;
  }
  originalLog.apply(console, args);
};

englishCustomMessages.ra.notification.item_doesnt_exist =
    '서버 데이터 오류로 인해 접근이 불가능합니다.';

const i18nProvider = polyglotI18nProvider(
    (locale) => (locale === 'fr' ?
        englishCustomMessages :
        englishCustomMessages),
    'en',
);

const App = () => (
    <Admin
        i18nProvider={i18nProvider}
        loginPage={Login}
        authProvider={authProvider}
        disableTelemetry
        title={process.env.REACT_APP_TITLE}
        dataProvider={springBootServerProvider(`${Constants.apiUrl}`)}
        theme={theme}
        layout={Layout}
    >
      {(permissions) => {
        if (permissions === 'admin') {
          return adminResource;
        } else {
          return null;
        }
      }}
    </Admin>
);

export default App;
