import React from 'react';
import {Login, LoginForm} from 'react-admin';
import {CardContent, Typography, Avatar} from '@material-ui/core';
import {withStyles} from '@material-ui/styles';

const publicPath = process.env.PUBLIC_URL;

const CustomLoginForm = props => (
    <div>
      <CardContent style={{paddingTop: 0, paddingBottom: 0}}>
        <Avatar src={'/logo512.png'}
                style={{width: 80, height: 80, margin: 'auto'}}/>
        <Typography align={'center'} variant={'subtitle2'}
                    color={'textSecondary'}>
          Management System
        </Typography>
      </CardContent>
      <LoginForm {...props} />
    </div>
);

const bgImage = `${publicPath}/images/auth-background.svg`;
const styles = {
  backgroundColor: '#333',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '50% 100%',
  display: 'flex',
  justifyContent: 'center',
};

const CustomLoginPage = props => (
    <Login {...props} backgroundImage={bgImage} style={styles}>
      <CustomLoginForm {...props}/>
    </Login>
);

const formStyle = ({
  icon: {display: 'none'},
});

export default withStyles(formStyle)(CustomLoginPage);
