import * as React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  Loading,
} from 'react-admin';
import {CustomPageTitle} from '../Layouts';
import {parse} from 'query-string';
import CommentReferenceInput from './CommentReferenceInput'
import {trimmedMinLength} from "../custom/customValidate";

export const HelpCommentCreate = (props) => {
  const {helpId: help_id_string} = parse(props.location.search);
  const helpId = help_id_string ? help_id_string : null;

  return (props.loading) ? <Loading/> : (
      <Create {...props}
              title={<CustomPageTitle title={'New Help Comment'} {...props}/>}>
        <SimpleForm undoable={false} defaultValue={{helpId: helpId}}>
          <CommentReferenceInput
              source="helpId"
              reference="help"
              validate={required()}
              perPage={100}
          />
          <TextInput source="comment" multiline fullWidth validate={[required(), trimmedMinLength(1)]}/>
        </SimpleForm>
      </Create>
  );
};

export default HelpCommentCreate;
