import React, { useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';

import IconImageEye from '@material-ui/icons/RemoveRedEye';
import { SimpleShowLayout, TextField, useGetOne } from 'react-admin';
import {AppBar as MuiAppBar, Toolbar as MuiToolbar, Button as MuiButton, Container, Typography} from '@material-ui/core';
import {CloseSharp} from '@material-ui/icons';

const useStyles = makeStyles({
  field: {
    '& span': {
      display: 'block',
      minWidth: '300px',
      maxWidth: '20em',
      width: '30%',
    }
  },
  spacer: {
    flex: 1,
  },
});

const QuickNoticeShowButton = ({ id }) => {
  const [showPanel, setShowPanel] = useState(false);
  const classes = useStyles();
  const { data } = useGetOne('notices', id);

  const handleClick = () => {
    setShowPanel(true);
  };

  const handleCloseClick = () => {
    setShowPanel(false);
  };

  return (
      <>
        <div style={{textAlign: 'left', marginBottom: '1em', marginLeft:10}}>
          <MuiButton variant={'outlined'} size={'small'} color={'primary'} endIcon={<IconImageEye />} onClick={handleClick} >
            Show Notice Context
          </MuiButton>
        </div>
        <Drawer anchor="right" open={showPanel} onClose={handleCloseClick}>
          <MuiAppBar position={'relative'} >
            <MuiToolbar variant={'dense'}>
              <Typography variant={'h6'}>
                Notice Text
              </Typography>
              <span className={classes.spacer} />
              <MuiButton color={'inherit'} onClick={handleCloseClick} >
                <CloseSharp />
              </MuiButton>
            </MuiToolbar>
          </MuiAppBar>

          <Container>
            <SimpleShowLayout
                record={data}
                basePath="/notice-comments"
                resource="notice-comments"
            >
              <TextField source="id" />
              <TextField source="title" className={classes.field} />
              <TextField source="content" className={classes.field} style={{whiteSpace:'pre-wrap'}} />
            </SimpleShowLayout>
          </Container>

        </Drawer>
      </>
  );
};

export default QuickNoticeShowButton;
