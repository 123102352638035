import * as React from 'react';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '@material-ui/icons/GetApp';
import {
  fetchRelatedRecords,
  useDataProvider,
  useNotify,
  useListContext,
  useResourceContext,
} from 'ra-core';
import { Button } from 'react-admin';

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};

const CustomExportButton = props => {
  const {
    maxResults = 1000,
    onClick,
    label = 'ra.action.export',
    icon = defaultIcon,
    exporter: customExporter,
    sort,
    ...rest
  } = props;
  const {
    filter,
    filterValues,
    currentSort,
    exporter: exporterFromContext,
    total,
  } = useListContext(props);
  const resource = useResourceContext(props);
  const exporter = customExporter || exporterFromContext;
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const handleClick = useCallback(
      event => {

          const urlParams = new URLSearchParams(document.URL);
          const page = urlParams.get('page');
          const perPage = urlParams.get('perPage');

          dataProvider
            .getList(resource, {
              sort: currentSort || sort,
              filter: filter
                  ? { ...filterValues, ...filter }
                  : filterValues,
              pagination: { page: page ? Number(page) : 1, perPage: perPage ? Number(perPage) : 10 },
            })
            .then(
                ({ data }) =>
                    exporter &&
                    exporter(
                        data,
                        fetchRelatedRecords(dataProvider),
                        dataProvider,
                        resource
                    )
            )
            .catch(error => {
              console.error(error);
              notify('ra.notification.http_error', 'warning');
            });
        if (typeof onClick === 'function') {
          onClick(event);
        }
      },
      [
        currentSort,
        dataProvider,
        exporter,
        filter,
        filterValues,
        maxResults,
        notify,
        onClick,
        resource,
        sort,
      ]
  );

  return (
      <Button
          onClick={handleClick}
          label={label}
          disabled={total === 0}
          {...sanitizeRestProps(rest)}
      >
        {icon}
      </Button>
  );
};

const defaultIcon = <DownloadIcon />;

var sanitizeRestProps = function (_a) {
    var basePath = _a.basePath, filterValues = _a.filterValues, resource = _a.resource, rest = __rest(_a, ["basePath", "filterValues", "resource"]);
    return rest;
};

CustomExportButton.propTypes = {
  basePath: PropTypes.string,
  exporter: PropTypes.func,
  filterValues: PropTypes.object,
  label: PropTypes.string,
  maxResults: PropTypes.number,
  resource: PropTypes.string,
  sort: PropTypes.exact({
    field: PropTypes.string,
    order: PropTypes.string,
  }),
  icon: PropTypes.element,
};

export default CustomExportButton;
