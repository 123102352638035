import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { useTimeout } from 'ra-core';

const CustomAutocompleteInputLoader = ({ timeout = 1000 }) => {
  const oneSecondHasPassed = useTimeout(timeout);

  return null;

  // TODO EDIT 후 새로고침 시 사라지지 않는 경우 발생
  if (oneSecondHasPassed) {
    return <CircularProgress size={24} />;
  }

  return null;
};

export default CustomAutocompleteInputLoader;
