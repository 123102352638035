import * as React from 'react';
import {
  Edit,
  TabbedForm,
  FormTab,
  TextField,
  Toolbar,
  ReferenceField,
  DateField,
  SaveButton,
  TextInput,
  BooleanInput,
  required,
  FormDataConsumer,
} from 'react-admin';
import {Button} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {
  Check as ConfirmedIcon, EmojiEvents as AwardedIcon,
  EmojiEvents as AwardsIcon,
} from '@material-ui/icons';
import {CustomPageTitle} from '../Layouts';
import {Link} from 'react-router-dom';

const useCustomToolbarStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const CustomCommentButton = ({record}) => {
  if (!record.isAwarded) {
    return (
        <Button color={'primary'} type={'button'} variant={'contained'}
                startIcon={<AwardsIcon/>} component={Link} to={{
          pathname: '/contest-awards/create',
          search: `?applicantId=${record.id}`,
        }}>
          Award this applicant
        </Button>
    );
  }

  return (
      <Button style={{backgroundColor: '#1dbd61'}} color={'primary'}
              type={'button'} variant={'contained'}
              startIcon={<AwardsIcon/>} component={Link} to={{
        pathname: `/contest-awards/${record.id}`,
      }}>
        Changing award
      </Button>
  );
};

const ContestApplicantToolbar = props => {
  const isChanged = !props.pristine;

  return (
      <Toolbar {...props} classes={useCustomToolbarStyles()}>
        <SaveButton disabled={!isChanged}/>
        {/*<CustomCommentButton/>*/}
      </Toolbar>
  );
};

const CheckField = ({record, source, icon, htmlColor}) => {
  const confirmed = record[source];
  const Icon = !icon ? () => <ConfirmedIcon/> : () => icon;

  if (!confirmed) {
    return <span/>;
  }
  return (
      <div>
        <span style={{color: htmlColor || 'inherit'}}><Icon/></span>
      </div>
  );
};

const useStyles = makeStyles({
  hide: {
    height: '0px',
    visibility: 'hidden',
  },
  show: {
    visibility: 'visible',
  }
});

const DynamicField = ({formData, ...props}) => {
  const classes = useStyles();
  return <TextInput className={!formData.isAwarded ? classes.hide : classes.show} resettable label="Award title" source={'awardTitle'} defaultValue={'gold'} validate={[required()]} />
};

export const ContestApplicantEdit = (props) => (
    <Edit {...props} mutationMode="optimistic" title={<CustomPageTitle {...props} />}>
      <TabbedForm undoable toolbar={<ContestApplicantToolbar/>}>

        <FormTab label="info">
          <TextField source="id"/>
          <CheckField source="isAwarded"
                      icon={<AwardedIcon/>} htmlColor={'#f5b423'}/>
          <ReferenceField label="Contest Title" reference='contests'
                          source='contestId'>
            <TextField source="title"/>
          </ReferenceField>
          <ReferenceField label="Contest Apply Start" reference='contests'
                          source='contestId' link={false}>
            <DateField source="applyStartAt"/>
          </ReferenceField>
          <ReferenceField label="Contest Apply End" reference='contests'
                          source='contestId' link={false}>
            <DateField source="applyEndAt"/>
          </ReferenceField>
          <ReferenceField label="Novel Title" reference='novels'
                          source='novelId'>
            <TextField source="title"/>
          </ReferenceField>

          <ReferenceField reference='users' source='authorId'>
            <TextField source="displayName"/>
          </ReferenceField>
          <DateField source="applyAt" showTime/>
          <ReferenceField label="Novel Create At" reference='novels'
                          source='novelId' link={false}>
            <DateField source="createAt"/>
          </ReferenceField>
          <BooleanInput label={'is awarded'} source="isAwarded"/>
          <FormDataConsumer>
            {
              ({formData, getSource, ...rest}) => <DynamicField formData={formData} {...rest}/>
            }
          </FormDataConsumer>
        </FormTab>

        <FormTab label="contest" path={'contest'}>
          <ReferenceField label="Contest Title" reference='contests'
                          source='contestId'>
            <TextField source="title"/>
          </ReferenceField>
          <ReferenceField label="Contest Content" reference='contests'
                          source='contestId' link={false}>
            <TextField source="content" style={{whiteSpace: 'pre-wrap'}}/>
          </ReferenceField>
          <ReferenceField label="Contest Apply Start" reference='contests'
                          source='contestId' link={false}>
            <DateField source="applyStartAt"/>
          </ReferenceField>
          <ReferenceField label="Contest Apply End" reference='contests'
                          source='contestId' link={false}>
            <DateField source="applyEndAt"/>
          </ReferenceField>
          <ReferenceField label="Contest Display Start" reference='contests'
                          source='contestId' link={false}>
            <DateField source="viewStartAt"/>
          </ReferenceField>
          <ReferenceField label="Contest Display End" reference='contests'
                          source='contestId' link={false}>
            <DateField source="viewEndAt"/>
          </ReferenceField>
          <ReferenceField label="Contest Tag" reference='contests'
                          source='contestId' link={false}>
            <TextField source="tag"/>
          </ReferenceField>
          <ReferenceField label="Contest Type" reference='contests'
                          source='contestId' link={false}>
            <TextField source="contestType"/>
          </ReferenceField>
          <ReferenceField label="Contest Create At" reference='contests'
                          source='contestId' link={false}>
            <DateField source="createAt" showTime/>
          </ReferenceField>
        </FormTab>

        <FormTab label="novel" path={'novel'}>
          <ReferenceField label="Novel Title" reference='novels'
                          source='novelId'>
            <TextField source="title"/>
          </ReferenceField>
          <ReferenceField label="Novel Summary" reference='novels'
                          source='novelId' link={false}>
            <TextField source="summary" style={{whiteSpace: 'pre-wrap'}}/>
          </ReferenceField>

          <ReferenceField label="genreCode" reference='novels' source='novelId'
                          link={false}>
            <TextField source="genreCode"/>
          </ReferenceField>

          <ReferenceField label="genre" reference='novels' source='novelId'
                          link={false}>
            <TextField source="genreName"/>
          </ReferenceField>
          <ReferenceField reference='users' source='authorId'>
            <TextField source="displayName"/>
          </ReferenceField>
          <ReferenceField label="Novel Create At" reference='novels'
                          source='novelId' link={false}>
            <DateField source="createAt" showTime/>
          </ReferenceField>
          <ReferenceField label="Last Chapter Update At" reference='novels'
                          source='novelId' link={false}>
            <DateField source="lastChapterUpdateAt" showTime/>
          </ReferenceField>
        </FormTab>
      </TabbedForm>
    </Edit>
);

export default ContestApplicantEdit;
